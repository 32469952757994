import { Box, Divider, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useContextSelector } from 'use-context-selector';
import { I18nKey } from '../../../../../translations/I18nKey';
import { useStyles } from '../../../../../utils/util';
import { LicensedFeaturesContext } from '../../../../Contexts/LicensedFeaturesContext';
import {
  GEOFENCE_AIS_DISPLAY_CONFIG,
  GEOFENCE_VMS_DISPLAY_CONFIG,
} from '../../config';
import { IUUSettingsStyles, tabIndicatorProps } from '../IUUSettingsStyles';
import { GeofenceAIS } from './GeofenceAIS';
import { GeofenceWatchListStyles } from './GeofenceStyles';
import { GeofenceWatchlistProps } from './GeofenceTypes';
import { GeofenceVMS } from './GeofenceVMS';

export const GeofenceWatchlist: React.FC<GeofenceWatchlistProps> = ({
  AISData,
  VMSData,
  geofenceWatchlist,
  setGeofenceWatchlist,
}) => {
  const intl = useIntl();
  const features = useContextSelector(
    LicensedFeaturesContext,
    (state) => state.features
  );
  const classes = useStyles(IUUSettingsStyles, [])();
  const validFeatureTabs: any = {
    AIS: 'GF_AIS',
    VMS: 'GF_VMS',
    'Dark Targets': 'GF_DARKTARGETS',
    'RF Signals': 'GF_RFSIGNALS',
  };
  const [tabName, setTabName] = useState<string>(
    validFeatureTabs[
      Object.keys(validFeatureTabs).filter((f: any) => features.includes(f))[0]
    ]
  );
  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabName(newValue);
  };

  return (
    <Box
      sx={{
        minHeight: '288px',
        maxHeight: '288px',
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}
    >
      <Divider sx={{ borderColor: '#333333', my: 2 }} flexItem />
      <Box sx={{ width: '100%', mt: 2 }}>
        <Box
          sx={{
            '& .MuiButtonBase-root': {
              padding: '12px 12px',
            },
          }}
        >
          <Tabs
            value={tabName}
            onChange={onTabChange}
            TabIndicatorProps={tabIndicatorProps}
          >
            {features.includes('AIS') && (
              <Tab
                value="GF_AIS"
                label={intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_TABS_AIS,
                })}
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            )}
            {features.includes('VMS') && (
              <Tab
                value="GF_VMS"
                label={intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_TABS_VMS,
                })}
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            )}
            {features.includes('Dark Targets') && (
              <Tab
                value="GF_DARKTARGETS"
                label={intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_DARK_TARGETS,
                })}
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                disabled
              />
            )}
            {features.includes('RF Signals') && (
              <Tab
                value="GF_RFSIGNALS"
                label={intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_RF_SIGNALS,
                })}
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                disabled
              />
            )}
            {/* <Tab
              value="GF_LRIT"
              label={intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_GEOFENCE_LRIT,
              })}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
              disabled
            /> */}
          </Tabs>
        </Box>
        <Box>
          <TabPanel name={tabName} index="GF_AIS">
            {AISData.length > 0 ? (
              <GeofenceAIS
                data={AISData}
                geofenceWatchlist={geofenceWatchlist}
                setGeofenceWatchlist={setGeofenceWatchlist}
                displaySectionsConfig={GEOFENCE_AIS_DISPLAY_CONFIG}
                type="ais"
              />
            ) : (
              <Typography sx={GeofenceWatchListStyles.noDataMsg}>
                {intl.formatMessage({
                  id: I18nKey.PRODUCTS_NO_DATA_MSG,
                })}
              </Typography>
            )}
          </TabPanel>
          <TabPanel name={tabName} index="GF_VMS">
            {VMSData.length > 0 ? (
              <GeofenceVMS
                data={VMSData}
                geofenceWatchlist={geofenceWatchlist}
                setGeofenceWatchlist={setGeofenceWatchlist}
                displaySectionsConfig={GEOFENCE_VMS_DISPLAY_CONFIG}
                type="vms"
              />
            ) : (
              <Typography sx={GeofenceWatchListStyles.noDataMsg}>
                {intl.formatMessage({
                  id: I18nKey.PRODUCTS_NO_DATA_MSG,
                })}
              </Typography>
            )}
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
};

const TabPanel: React.FC<any> = (props) => {
  const { children, name, index } = props;
  return <>{name === index && <> {children} </>}</>;
};
