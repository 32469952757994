import _ from 'lodash';
import { isEmpty } from 'lodash';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import moment from 'moment';
import { VESSEL_TYPE_COLORS } from '../../../constants/Constants';
import { dateToString } from '../../../utils/util';
import { vesselLayout } from '../../IUUDashboard/DashboardConfig';
import { Geojson, AISData } from '../../types';
import {
  DTColors,
  geofencePolygonColorConfig,
  RFColors,
  vesselIcons,
  VMSBounds,
} from './config';
import { FillAreaService } from '../Products/MapLayers/FillAreaService';
import { removeLayerSource, stringToColorConverter } from '../HelperService';
import {
  CircleMode,
  DragCircleMode,
  DirectMode,
  SimpleSelectMode,
} from 'mapbox-gl-draw-circle';
export class IUUService {
  public vesselList: Record<string, any> = {};

  public static formGeojson = (data: any[], layerType: string) => {
    let position: Geojson = {
      type: 'FeatureCollection',
      features: [],
    };
    Object.values(data).forEach((vessel: any) => {
      switch (layerType) {
        case 'AIS':
          position.features.push(this.getAISPointGeojson(vessel));
          break;
        case 'VMS':
          position.features.push(this.getVMSPointGeojson(vessel));
          break;
        case 'Buoy':
          position.features.push(this.getBuoysPointGeojson(vessel));
          break;
        case 'Vessel':
          position.features.push(this.getVesselsPointGeojson(vessel));
          break;
        default:
          console.error('Invalid layer type!');
      }
    });
    return position;
  };

  public static getBounds = (
    map: mapboxgl.Map | null,
    vesselSource?: string
  ) => {
    const bounds =
      vesselSource === 'VMS'
        ? VMSBounds
        : {
            topRight: String([
              map?.getBounds().getNorthEast().lat,
              map?.getBounds().getNorthEast().lng,
            ]),
            bottomLeft: String([
              map?.getBounds().getSouthWest().lat,
              map?.getBounds().getSouthWest().lng,
            ]),
          };
    return bounds;
  };

  public static getDates = (
    dataDuration: number,
    dataFetchToDate?: string,
    toUTC: boolean = false
  ) => {
    const toDate = dataFetchToDate
      ? moment(dataFetchToDate, 'YYYYMMDDHHmmss')
      : moment();
    const fromDate = moment(toDate);
    fromDate.set('hour', toDate.hours() - dataDuration);
    return {
      toDate: dateToString(toDate, 'YYYYMMDDHHmmss', toUTC),
      fromDate: dateToString(fromDate, 'YYYYMMDDHHmmss', toUTC),
    };
  };

  public static filterVesselData = (
    data: AISData[],
    filters: any,
    dataDuration: number,
    dataFetchToDate: string
  ) => {
    const specificFilters = ['vesselName', 'vesselId'];
    const { fromDate, toDate } = this.getDates(
      dataDuration,
      dataFetchToDate,
      false
    );
    const nonEmptyFilters = _.omitBy(filters, isEmpty);
    const highLevelFilterKeys = Object.keys(nonEmptyFilters).filter(
      (k: any) => !specificFilters.includes(k)
    );
    const lowLevelFilterKeys = Object.keys(nonEmptyFilters).filter((k: any) =>
      specificFilters.includes(k)
    );
    const filteredVesselsForHigherLevel = _.filter(data, (item) => {
      return (
        highLevelFilterKeys.every((key) =>
          key === 'speed'
            ? IUUService.inRange(
                parseFloat(item.sog),
                filters['speed'].from,
                filters['speed'].to
              )
            : nonEmptyFilters[key].includes(item[key] || '')
        ) &&
        moment(item.date, 'YYYY-MM-DDTHH:mm:ss').isBetween(
          moment(fromDate, 'YYYYMMDDHHmmss'),
          moment(toDate, 'YYYYMMDDHHmmss')
        )
      );
    });
    let filteredData = filteredVesselsForHigherLevel;
    if (lowLevelFilterKeys.length) {
      filteredData = _.filter(filteredVesselsForHigherLevel, (item) => {
        return lowLevelFilterKeys.some((key) =>
          nonEmptyFilters[key].includes(item[key])
        );
      });
    }
    return filteredData;
  };

  public static filterVMSData = (
    data: any[],
    filters: any,
    dataDuration: number,
    dataFetchToDate: string
  ) => {
    const specificFilters = ['vesselName'];
    const { fromDate, toDate } = this.getDates(
      dataDuration,
      dataFetchToDate,
      false
    );
    const nonEmptyFilters = _.omitBy(filters, isEmpty);
    const highLevelFilterKeys = Object.keys(nonEmptyFilters).filter(
      (k: any) => !specificFilters.includes(k)
    );
    const lowLevelFilterKeys = Object.keys(nonEmptyFilters).filter((k: any) =>
      specificFilters.includes(k)
    );
    const filteredVesselsForHigherLevel = _.filter(data, (item) => {
      return (
        highLevelFilterKeys.every((key) =>
          key === 'speed'
            ? IUUService.inRange(
                parseFloat(item.speed),
                filters['speed'].from,
                filters['speed'].to
              )
            : nonEmptyFilters[key].includes(item[key] || '')
        ) &&
        moment(item.posDate, 'YYYY-MM-DDTHH:mm:ss').isBetween(
          moment(fromDate, 'YYYYMMDDHHmmss'),
          moment(toDate, 'YYYYMMDDHHmmss')
        )
      );
    });
    let filteredData = filteredVesselsForHigherLevel;
    if (lowLevelFilterKeys.length) {
      filteredData = _.filter(filteredVesselsForHigherLevel, (item) => {
        return lowLevelFilterKeys.some((key) =>
          nonEmptyFilters[key].includes(item[key])
        );
      });
    }
    return filteredData;
  };

  public static filterBuoyData = (data: any[], filters: any) => {
    const specificFilters = ['buoysId'];
    const nonEmptyFilters = _.omitBy(filters, isEmpty);
    const highLevelFilterKeys = Object.keys(nonEmptyFilters).filter(
      (k: any) => !specificFilters.includes(k)
    );
    const lowLevelFilterKeys = Object.keys(nonEmptyFilters).filter((k: any) =>
      specificFilters.includes(k)
    );
    const filteredVesselsForHigherLevel = _.filter(data, (item) => {
      return highLevelFilterKeys.every((key) =>
        nonEmptyFilters[key].includes(item[key] || '')
      );
    });
    let filteredData = filteredVesselsForHigherLevel;
    if (lowLevelFilterKeys.length) {
      filteredData = _.filter(filteredVesselsForHigherLevel, (item) => {
        return lowLevelFilterKeys.some((key) =>
          nonEmptyFilters[key].includes(item[key])
        );
      });
    }
    return filteredData;
  };

  public static filterFishCatchData = (data: any, filters: any) => {
    if (parseInt(filters.dataDurationValue) !== 0) {
      data = data.filter((d: any) =>
        moment(d.catchDate, 'YYYY-MM-DDTHH:mm:ss').isBetween(
          moment(filters.dataDuration.fromDate, 'YYYYMMDDHHmmss'),
          moment(filters.dataDuration.toDate, 'YYYYMMDDHHmmss')
        )
      );
    }

    if (filters.vessels.length) {
      data = data.filter((d: any) => filters.vessels.includes(d.vesselName));
    }
    if (filters.species.length) {
      data = data.filter((d: any) =>
        Object.values(d.catchDetails).some((c: any) =>
          filters.species.includes(c.name)
        )
      );
    }
    if (filters.fishingTechniques.length) {
      data = data.filter((d: any) =>
        filters.fishingTechniques.includes(d.fishingTechnique)
      );
    }
    if (data.length) return data;
    return [];
  };
  public static inRange = (n: number, start: number, end: number) => {
    return n <= end && n >= start ? true : false;
  };

  public static getAISPointGeojson = (vessel: any) => {
    return {
      type: 'Feature',
      properties: {
        vesselID: vessel.vesselId,
        color: VESSEL_TYPE_COLORS[vessel.vesselType],
        course: vessel.otherDetails.cog,
        breach: false, // TODO: hardcoding for now, to be handled later,
        country: vessel.flagCountry,
        name: vessel.vesselName,
        type: vessel.vesselType,
        date: vessel.date,
        sog: vessel.sog,
        lat: vessel.latitude,
        lon: vessel.longitude,
      },
      geometry: {
        type: 'Point',
        coordinates: [vessel.longitude, vessel.latitude],
      },
    };
  };

  public static getVMSPointGeojson = (vessel: any) => {
    return {
      type: 'Feature',
      properties: {
        vesselID: vessel.vesselId,
        color: stringToColorConverter(vessel.fleetName),
        breach: false, // TODO: hardcoding for now, to be handled later,
        name: vessel.name,
        category: vessel.category,
        date: vessel.posDate,
        sog: vessel.speed,
        lat: vessel.latitude,
        lon: vessel.longitude,
        fleetId: vessel.fleetId,
        fleetName: vessel.fleetName,
        id: vessel.id,
        positionId: vessel.positionId,
        status: vessel.status,
        uniqueId: vessel.uniqueId,
      },
      geometry: {
        type: 'Point',
        coordinates: [vessel.longitude, vessel.latitude],
      },
    };
  };

  public static getBuoysPointGeojson = (buoy: any) => {
    return {
      type: 'Feature',
      properties: {
        buoyID: buoy.buoysId,
        color: VESSEL_TYPE_COLORS['Buoy'],
        course: buoy.course,
        velocity: buoy.velocity,
        breach: false,
        vesselName: buoy.vesselName,
        date: buoy.date,
        lat: buoy.latitude,
        lon: buoy.longitude,
      },
      geometry: {
        type: 'Point',
        coordinates: [buoy.longitude, buoy.latitude],
      },
    };
  };

  public static getVesselsPointGeojson = (vessel: any) => {
    return {
      type: 'Feature',
      properties: {
        vesselID: vessel.vesselId,
        color: VESSEL_TYPE_COLORS['Vessel'],
        course: vessel.course,
        velocity: vessel.velocity,
        breach: false,
        vesselName: vessel.vesselName,
        date: vessel.date,
        lat: vessel.latitude,
        lon: vessel.longitude,
      },
      geometry: {
        type: 'Point',
        coordinates: [vessel.longitude, vessel.latitude],
      },
    };
  };

  public static removeLayer = (
    map: mapboxgl.Map | null,
    layerID: string,
    removeMapLayerId: (key: string, layerId: string) => void
  ) => {
    if (map?.getLayer(layerID) && map?.getSource(layerID)) {
      map.removeLayer(layerID);
      removeMapLayerId('vessels', layerID);
      map.removeSource(layerID);
    }
  };

  public static drawVeselsOnMap = (
    map: mapboxgl.Map | null,
    layerType: string,
    data: AISData[] | any[],
    layerOrder: (layerName: string) => any,
    addMapLayerId: (key: string, layerId: string) => void
  ) => {
    const vesselGeojson = IUUService.formGeojson(data, layerType);
    map?.addSource(layerType, {
      type: 'geojson',
      data: vesselGeojson,
    });
    let imageName = layerType + 'Vessels';
    if (!map?.hasImage(imageName)) {
      map?.loadImage(vesselIcons[layerType], (error: any, image: any) => {
        if (error) throw error;
        image && map?.addImage(imageName, image, { sdf: true });
      });
    }
    addMapLayerId('vessels', layerType);
    map?.addLayer(
      {
        id: layerType,
        type: 'symbol',
        source: layerType,
        layout: vesselLayout,
        paint: {
          'icon-color': { type: 'identity', property: 'color' },
        },
      },
      layerOrder('vessels')
    );
    if (layerType !== 'Buoy' && layerType !== 'VMS') {
      map?.setLayoutProperty(layerType, 'icon-rotate', {
        type: 'identity',
        property: 'course',
      });
    }
    map?.setLayoutProperty(layerType, 'icon-image', imageName);
  };

  public static drawShape(
    map: mapboxgl.Map | null,
    type: string,
    drawControl: any,
    setDrawControl: any,
    drawingData: any,
    setUnselectMode: any
  ) {
    let drawData: any = {};
    if (map) {
      if (map.hasControl(drawControl)) {
        drawData = drawControl?.getAll();
        let features: any = [];
        if (drawData.features.length) {
          drawData.features.forEach((f: any) => {
            if (
              f.geometry.type === 'Polygon' &&
              f.geometry.coordinates[0].length > 2
            ) {
              features.push({
                ...f,
                properties: { ...f.properties, id: f.id },
              });
            }
          });
        }
        drawData.features = features;
        map?.removeControl(drawControl);
      }

      let draw: any = new MapboxDraw({
        displayControlsDefault: false,
        styles: geofencePolygonColorConfig,
        userProperties: true,
        modes: {
          ...MapboxDraw.modes,
          draw_circle: CircleMode,
          drag_circle: DragCircleMode,
          direct_select: DirectMode,
          simple_select: SimpleSelectMode,
        },
      });
      setDrawControl(draw);
      map.getCanvas().style.cursor = 'crosshair';
      map.addControl(draw);
      let feat: any = [];
      map?.on('draw.create', (e) => {
        feat = e.features;
      });
      if (type === 'circle') {
        draw.changeMode('draw_circle', { initialRadiusInKm: 100 });
      } else {
        draw.changeMode('draw_polygon');
      }
      if (drawData && drawData?.features?.length) {
        draw.add(drawData);
        feat = drawData;
      } else if (drawingData && drawingData?.features?.length) {
        draw.add(drawingData);
        feat = drawingData;
      }
      map?.on('draw.modechange', (e) => {
        if (
          type === 'polygon' &&
          e.mode === 'simple_select' &&
          feat.length === 0
        ) {
          setUnselectMode(true);
        }
      });
      if (map.getSource('geofenceDrawing')) {
        removeLayerSource(map, 'geofenceDrawing', [
          'geofenceDrawing',
          'geofenceDrawingoutline',
        ]);
      } else {
        if (type === 'circle') {
          draw.changeMode('draw_circle', { initialRadiusInKm: 100 });
        } else {
          draw.changeMode('draw_polygon');
        }
      }
    }
  }

  public static savePolygon(
    map: mapboxgl.Map | null,
    drawControl: any,
    displayProperties: any
  ) {
    if (map?.hasControl(drawControl)) {
      let drawingData = drawControl?.getAll();
      let features: any = [];
      if (drawingData.features.length) {
        drawingData.features.forEach((f: any) => {
          if (
            f.geometry.type === 'Polygon' &&
            f.geometry.coordinates[0].length > 2
          ) {
            features.push({ ...f, properties: { ...f.properties, id: f.id } });
          }
        });
      }
      drawingData.features = features;
      IUUService.disableDrawing(map, drawControl);
      if (
        drawingData?.features.length &&
        drawingData.features[0].geometry.coordinates[0].includes(null)
      ) {
        return {};
      } else {
        map &&
          removeLayerSource(map, 'geofenceDrawing', [
            'geofenceDrawing',
            'geofenceDrawingoutline',
          ]);
        map &&
          FillAreaService.addFillAreaLayer(
            map,
            'geofenceDrawing',
            drawingData,
            true,
            {
              'fill-color': displayProperties.fillColor,
              'fill-opacity': 0.5,
            },
            false,
            'geofence',
            true,
            {
              'line-color': displayProperties.strokeColor,
              'line-width': 3,
            },
            () => {},
            () => {}
          );
        return drawingData;
      }
    }
  }

  public static disableDrawing(map: mapboxgl.Map | null, drawControl: any) {
    if (map) {
      map.getCanvas().style.cursor = '';
    }
    if (map?.hasControl(drawControl)) {
      drawControl.deleteAll();
      drawControl.trash();
      map?.removeControl(drawControl);
    }
  }

  public static changeDisplayProperty(
    map: mapboxgl.Map | null,
    strokeColor?: any,
    fillColor?: any,
    strokeWidth?: number
  ) {
    let id = '';
    if (map?.getSource('geofenceDrawing')) id = 'geofenceDrawing';
    if (id) {
      map?.setPaintProperty(id + 'outline', 'line-color', strokeColor);
      map?.setPaintProperty(id + 'outline', 'line-width', strokeWidth);
      map?.setPaintProperty(id, 'fill-color', fillColor);
    }
  }

  public static drawRFSignals(
    map: mapboxgl.Map | null,
    data: any,
    signal: string,
    layerOrder: (layerName: string) => any,
    addMapLayerId: (key: string, layerId: string) => void
  ) {
    map &&
      FillAreaService.addFillAreaLayer(
        map,
        signal,
        data,
        true,
        {
          'fill-color': RFColors[signal],
          'fill-opacity': 0.7,
        },
        true,
        'RF',
        false,
        {},
        addMapLayerId,
        layerOrder
      );
  }

  public static drawDarkTargets = (
    map: mapboxgl.Map | null,
    data: any,
    layer: string,
    layerOrder: (layerName: string) => any,
    addMapLayerId: (key: string, layerId: string) => void
  ) => {
    map?.addSource(layer, {
      type: 'geojson',
      data: data,
    });
    addMapLayerId('darkTargets', layer);
    map?.addLayer(
      {
        id: layer,
        type: layer === 'extents' ? 'line' : 'circle',
        source: layer,
      },
      layerOrder('darkTargets')
    );
    if (map?.getLayer(layer)) {
      map?.setPaintProperty(
        layer,
        layer === 'extents' ? 'line-color' : 'circle-color',
        DTColors[layer]
      );
    }
  };
}

export const getListFromArray = (array: any[], key: string) => {
  return array.map((element: any) => element[key]);
};

export const getArrayOfObjects = (array: any[], key: string) => {
  return array.map((element: any) => {
    return { [key]: element };
  });
};
