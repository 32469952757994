import React from 'react';
import { I18nKey } from '../../translations/I18nKey';
import Map from '../Map/Map';
import FishCatchDashboard from '../FishCatchDashboard/FishCatchDashboard';
import IUUDashboard from '../IUUDashboard/IUUDashboard';
import MapsMenu from './../../assets/icons/maps_unselected.svg';
import FishCatchMenu from './../../assets/icons/fish_catch_unselected.svg';
import IUUMenu from './../../assets/icons/iuu_unselected.svg';
import { SignIn } from '../PublicHome/SignIn';
import { ConfirmSignUp } from '../PublicHome/ConfirmSignUp';
import { ForgotPassword } from '../PublicHome/ForgotPassword';
import { SignUp } from '../PublicHome/SignUp';
import { withRedirectRoute } from './routeHOCs';

export interface Route {
  readonly path: string;
  readonly element: any;
  readonly icon?: string;
  readonly label?: string;
  readonly includeInNav?: boolean;
}

export const DEFAULT_PRIVATE_ROUTES: Route[] = [
  {
    path: '/',
    element: React.createElement(Map),
    icon: MapsMenu,
    label: I18nKey.ROUTER_MAPS,
    includeInNav: true,
  },
  {
    path: '/maps',
    element: React.createElement(Map),
    icon: MapsMenu,
    label: I18nKey.ROUTER_MAPS,
    includeInNav: false,
  },
];

export const LICENSED_PRIVATE_ROUTES: Route[] = [
  {
    path: '/fishcatch-dashboard',
    element: React.createElement(FishCatchDashboard),
    icon: FishCatchMenu,
    label: I18nKey.ROUTER_FISH_CATCH,
    includeInNav: true,
  },
  {
    path: '/tracking-dashboard',
    element: React.createElement(IUUDashboard),
    icon: IUUMenu,
    label: I18nKey.ROUTER_IUU,
    includeInNav: true,
  },
];

export const PUBLIC_ROUTES: Route[] = [
  {
    path: '/sign-in',
    element: React.createElement(withRedirectRoute(SignIn)),
  },
  {
    path: '/sign-up',
    element: React.createElement(withRedirectRoute(SignUp)),
  },
  {
    path: '/forgot-password',
    element: React.createElement(withRedirectRoute(ForgotPassword)),
  },
  {
    path: '/confirm-sign-up',
    element: React.createElement(withRedirectRoute(ConfirmSignUp)),
  },
];
