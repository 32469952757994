import {
  Box,
  Divider,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useEffect, useState } from 'react';
import _, { floor, isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../../translations/I18nKey';
import AutoComplete from '../../../../components/AutoComplete/AutoComplete';
import { Stepper } from '../../../../components/Stepper/Stepper';
import { Toggle } from '../../../../components/Toggle/Toggle';
import {
  IUU_SETTINGS_VMS_COLUMNS,
  IUU_SETTINGS_DEFAULT_DATE_DURATION,
} from '../config';
import { useStyles } from '../../../../utils/util';
import { getArrayOfObjects, getListFromArray } from '../IUUService';
import { IUUSettingsStyles } from './IUUSettingsStyles';
import { AISStyles } from './AISnVMS/AISnVMSStyles';

const precision = 1;

export const VMSSettings: React.FC<any> = ({
  data,
  displaySectionsConfig,
  applyChanges,
  setApplyChanges,
  onApply,
  tabType,
}) => {
  const intl = useIntl();
  const [filteredData, setFilteredData] = useState<any>(data);
  const [selectedCategory, setSelectedCategory] = useState<any>([]);
  const [selectedFleetName, setSelectedFleetName] = useState<any>([]);
  const [selectedVesselName, setSelectedVesselName] = useState<any>([]);
  const [fromSpeed, setFromSpeed] = useState(0);
  const [toSpeed, setToSpeed] = useState(25);
  const [showTracks, setShowTracks] = useState<boolean>(false);
  const [disableToggle, setDisableToggle] = useState<boolean>(true);
  const [dataDuration, setDataDuration] = useState<string>(
    localStorage.getItem('dataDuration') || IUU_SETTINGS_DEFAULT_DATE_DURATION
  );
  const onDateChange = (event: SelectChangeEvent) => {
    setDataDuration(event.target.value);
  };
  const classes = useStyles(IUUSettingsStyles, [])();

  const onCategoryChange = (e: any) => {
    let filteredVessels = _.filter(data, (vessel: any) => {
      return _.map(e, 'category').includes(vessel.category);
    });
    // To show all vessels when nothing is selected in VesselType
    if (isEmpty(filteredVessels)) filteredVessels = data;
    setFilteredData(filteredVessels);
    setSelectedCategory(e);
    const updatedFleetNames = _.filter(selectedFleetName, (name: any) => {
      return _.map(e, 'category').includes(name.category);
    });
    setSelectedFleetName(updatedFleetNames);
    const updatedVesselNames = _.filter(selectedVesselName, (name: any) => {
      return _.map(e, 'category').includes(name.category);
    });
    setSelectedVesselName(updatedVesselNames);
  };

  const onFleetNameChange = (e: any) => {
    let filteredVessels = _.filter(data, (vessel: any) => {
      return _.map(e, 'fleetName').includes(vessel.fleetName);
    });
    // To show all vessels when nothing is selected in VesselType
    if (isEmpty(filteredVessels)) filteredVessels = data;
    setFilteredData(filteredVessels);
    setSelectedFleetName(e);
    const updatedVesselNames = _.filter(selectedVesselName, (name: any) => {
      return _.map(e, 'fleetName').includes(name.fleetName);
    });
    setSelectedVesselName(updatedVesselNames);
  };

  const onVesselNameChange = (e: any) => {
    setSelectedVesselName(e);
  };

  const onToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowTracks(event.target.checked);
  };

  const getUniqueData = (data: any, type: string) => {
    return _.uniqBy(data, type);
  };

  const onFromSpeedChange = (event: any) => {
    setFromSpeed(floor(event.target.value, precision));
  };

  const onToSpeedChange = (event: any) => {
    setToSpeed(floor(event.target.value, precision));
  };

  useEffect(() => {
    const filters = JSON.parse(
      localStorage.getItem(displaySectionsConfig.localStorageKey) || '""'
    );
    if (filters.category !== undefined) {
      setSelectedCategory(getArrayOfObjects(filters.category, 'category'));
    }
    if (filters.fleetName !== undefined) {
      setSelectedFleetName(getArrayOfObjects(filters.fleetName, 'fleetName'));
    }
    if (filters.vesselName !== undefined) {
      setSelectedVesselName(
        getArrayOfObjects(filters.vesselName, 'vesselName')
      );
    }
    if (filters.speed) {
      setFromSpeed(floor(filters.speed.from, precision));
      setToSpeed(floor(filters.speed.to, precision));
    }
  }, []);

  useEffect(() => {
    if (applyChanges) {
      let filterData: any = {
        category: getListFromArray(selectedCategory, 'category'),
        fleetName: getListFromArray(selectedFleetName, 'fleetName'),
        vesselName: getListFromArray(selectedVesselName, 'vesselName'),
        speed: {
          from: fromSpeed,
          to: toSpeed,
        },
      };
      onApply(tabType, filterData, parseInt(dataDuration), {
        [tabType]: showTracks,
      });
      setApplyChanges(false);
    }
  }, [applyChanges]);

  useEffect(() => {
    if (selectedVesselName.length > 0 || selectedCategory.length > 0) {
      setDisableToggle(false);
    } else {
      setShowTracks(false);
      setDisableToggle(true);
    }
  }, [selectedVesselName, selectedCategory]);

  return (
    <>
      <Box className={classes.dateWrapper}>
        <Typography className={classes.dateDurationText} sx={{ mr: 1 }}>
          {intl.formatMessage({ id: I18nKey.IUU_DATA_DURATION })}
        </Typography>
        <FormControl
          className={classes.dateFormControl}
          sx={{ m: 1 }}
          size="small"
        >
          <Select
            id="iuu-select"
            displayEmpty
            value={dataDuration}
            onChange={onDateChange}
            className={classes.dateSelect}
          >
            <MenuItem value={6}>
              {intl.formatMessage({ id: I18nKey.IUU_DATE_6_HOURS })}
            </MenuItem>
            <MenuItem value={12}>
              {intl.formatMessage({ id: I18nKey.IUU_DATE_12_HOURS })}
            </MenuItem>
            <MenuItem value={24}>
              {intl.formatMessage({ id: I18nKey.IUU_DATE_24_HOURS })}
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      {_.isEmpty(data) ? (
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: '30%',
            color: 'white',
          }}
        >
          {' '}
          {intl.formatMessage({
            id: I18nKey.PRODUCTS_NO_DATA_MSG,
          })}
        </Typography>
      ) : (
        <Box
          sx={{
            mr: 1,
            '& .chipWrapper': {
              marginLeft: 0,
            },
            '& .MuiAutocomplete-root': {
              marginLeft: '-10px',
            },
          }}
        >
          {/* <>
            <Divider
              sx={{ borderColor: '#333333', mt: 2, mb: -2 }}
              flexItem
            ></Divider>
            <Box>
              <AutoComplete
                key={IUU_SETTINGS_VMS_COLUMNS.categoryColumnConfig[0].name}
                data={getUniqueData(
                  data,
                  IUU_SETTINGS_VMS_COLUMNS.categoryColumnConfig[0].name
                )}
                style={AISStyles.autocompleteStyle}
                // placeholder={intl.formatMessage({
                //   id: I18nKey.IUU_SETTINGS_MMSI_PLACEHOLDER,
                // })}
                placeholder="Search by Category"
                columnConfig={IUU_SETTINGS_VMS_COLUMNS.categoryColumnConfig}
                onSelectionChange={onCategoryChange}
                showSelectAllToggle={false}
                selectionData={selectedCategory}
                multiValueSeparator=" "
              />
            </Box>
          </> */}
          <>
            <Divider
              sx={{ borderColor: '#333333', mt: 2, mb: -2 }}
              flexItem
            ></Divider>
            <Box>
              <AutoComplete
                key={IUU_SETTINGS_VMS_COLUMNS.fleetNameColumnConfig[0].name}
                data={getUniqueData(
                  data,
                  IUU_SETTINGS_VMS_COLUMNS.fleetNameColumnConfig[0].name
                )}
                style={AISStyles.autocompleteStyle}
                placeholder={intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_FLEET_NAME_PLACEHOLDER,
                })}
                columnConfig={IUU_SETTINGS_VMS_COLUMNS.fleetNameColumnConfig}
                onSelectionChange={onFleetNameChange}
                showSelectAllToggle={false}
                selectionData={selectedFleetName}
              />
            </Box>
          </>
          <>
            <Divider
              sx={{ borderColor: '#333333', mt: 2, mb: -2 }}
              flexItem
            ></Divider>
            <Box>
              <AutoComplete
                key={IUU_SETTINGS_VMS_COLUMNS.vesselNameColumnConfig[0].name}
                data={getUniqueData(
                  filteredData,
                  IUU_SETTINGS_VMS_COLUMNS.vesselNameColumnConfig[0].name
                )}
                style={AISStyles.autocompleteStyle}
                placeholder={intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_VESSEL_NAME_PLACEHOLDER,
                })}
                columnConfig={IUU_SETTINGS_VMS_COLUMNS.vesselNameColumnConfig}
                onSelectionChange={onVesselNameChange}
                showSelectAllToggle={false}
                selectionData={selectedVesselName}
              />
            </Box>
          </>
          {displaySectionsConfig.showSpeed && (
            <>
              <Divider
                sx={{ borderColor: '#333333', mt: 2 }}
                flexItem
              ></Divider>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  color: '#FFF',
                  mt: 2,
                }}
              >
                <Typography sx={{ mb: 1 }}>
                  {intl.formatMessage({
                    id: I18nKey.IUU_SETTINGS_SPEED,
                  })}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ mr: 2 }}>
                    {intl.formatMessage({
                      id: I18nKey.IUU_SETTINGS_FROM_TO,
                    })}
                  </Typography>
                  <Box sx={{ width: '12%', mr: 1 }}>
                    <Stepper
                      value={fromSpeed}
                      key="from"
                      onChange={onFromSpeedChange}
                    />
                  </Box>
                  <Typography>-</Typography>
                  <Box
                    sx={{
                      width: '12%',
                      ml: 1,
                      mr: 2,
                      backgroundColor: '#242424',
                    }}
                  >
                    <Stepper
                      value={toSpeed}
                      key="from"
                      onChange={onToSpeedChange}
                    />
                  </Box>
                  <Typography>
                    {intl.formatMessage({
                      id: I18nKey.IUU_SETTINGS_KNOTS,
                    })}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
          {displaySectionsConfig.showTracks && (
            <>
              <Divider
                sx={{ borderColor: '#333333', my: 2 }}
                flexItem
              ></Divider>
              <Box sx={{ display: 'flex', mb: 1 }}>
                <FormGroup>
                  <FormControlLabel
                    sx={{
                      color: '#fff',
                      marginLeft: '0px',
                      '& .MuiFormControlLabel-label.Mui-disabled': {
                        color: '#6F6F6F',
                      },
                    }}
                    control={
                      <Toggle
                        disabled={disableToggle}
                        checked={showTracks}
                        onChange={onToggleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label={intl.formatMessage({
                      id: I18nKey.IUU_SETTINGS_SHOW_TRACKS,
                    })}
                    labelPlacement="start"
                  />
                </FormGroup>
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};
