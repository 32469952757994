import {
  Fab,
  Typography,
  Drawer,
  Box,
  Button,
  Divider,
  Toolbar,
} from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import FilterOpen from '../../assets/icons/filter_open.svg';
import FilterClose from '../../assets/icons/filter_closed.svg';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import reset from '../../assets/icons/reset.svg';
import { I18nKey } from '../../translations/I18nKey';
import {
  geofencesColumnConfig,
  fleetColumnConfig,
  vesselNamesColumnConfig,
} from './DashboardConfig';
import {
  useIUUDashboardStyles,
  IUUDashboardStyles,
} from './IUUDashboardStyles';

const VMSFilters: React.FC<any> = ({
  onFleetChange,
  onVesselNamesChange,
  onFenceChange,
  fleetList,
  vesselNamesList,
  fenceNameList,
  selectedVesselName = [],
  selectedFleet = [],
  selectedFenceName = [],
  onFilterReset,
  onApply,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const intl = useIntl();
  let fleetName = fleetList.map((d: any) => {
    return {
      fleetName: d.fleetName,
    };
  });
  let vesselNames = vesselNamesList.map((d: any) => {
    return {
      vesselName: d.vesselName,
    };
  });
  let fenceNames = _.cloneDeep(fenceNameList);
  const IUUDashboardClasses = useIUUDashboardStyles();

  return (
    <>
      <Fab
        color="primary"
        variant="extended"
        className={IUUDashboardClasses.filterContainerStyle}
        onClick={() => {
          setShowFilters(true);
        }}
      >
        <Typography className={IUUDashboardClasses.filterCloseText}>
          {intl.formatMessage({
            id: I18nKey.IUUDASHBOARD_FILTER,
          })}
        </Typography>

        <img height="30px" width="30px" src={FilterClose} alt=""></img>
      </Fab>
      <Drawer
        anchor="right"
        open={showFilters}
        variant="persistent"
        onClose={() => {}}
      >
        <div style={{ marginTop: '1em' }} />
        <Toolbar />
        <Box className={IUUDashboardClasses.filterBoxStyle}>
          <Box className={IUUDashboardClasses.filterDrawerStyle}>
            <Button
              onClick={() => {
                setShowFilters(false);
              }}
            >
              <Typography className={IUUDashboardClasses.filterHeadingStyle}>
                {intl.formatMessage({
                  id: I18nKey.IUUDASHBOARD_FILTER,
                })}
              </Typography>
              <img height="30px" width="30px" src={FilterOpen} alt=""></img>
            </Button>
          </Box>
          <Box>
            <Box>
              <AutoComplete
                key={'fences'}
                data={fenceNames}
                style={IUUDashboardStyles.autocompleteStyle}
                placeholder={intl.formatMessage({
                  id: I18nKey.IUUDASHBOARD_FILTERGEOFENCES,
                })}
                columnConfig={geofencesColumnConfig}
                onSelectionChange={onFenceChange}
                showSelectAllToggle={true}
                multiValueSeparator="-"
                groupBy={'type'}
                showClearAll={false}
                selectionData={selectedFenceName}
              />
            </Box>
            <Divider flexItem></Divider>
            <Box>
              <AutoComplete
                key={'fleetName'}
                data={fleetName}
                style={IUUDashboardStyles.autocompleteStyle}
                // placeholder={intl.formatMessage({
                //   id: I18nKey.IUUDASHBOARD_FILTERCOUNTRY,
                // })}
                placeholder="Fleet Name"
                columnConfig={fleetColumnConfig}
                onSelectionChange={onFleetChange}
                showSelectAllToggle={true}
                multiValueSeparator="-"
                showClearAll={false}
                selectionData={selectedFleet}
              />
            </Box>
            <Divider flexItem></Divider>
            <AutoComplete
              key={'vesselName'}
              data={vesselNames}
              style={IUUDashboardStyles.autocompleteStyle}
              // placeholder={intl.formatMessage({
              //   id: I18nKey.IUUDASHBOARD_FILTERTYPES,
              // })}
              placeholder="Vessel Name"
              columnConfig={vesselNamesColumnConfig}
              onSelectionChange={onVesselNamesChange}
              showSelectAllToggle={true}
              multiValueSeparator="-"
              showClearAll={false}
              selectionData={selectedVesselName}
            />
          </Box>

          <Divider flexItem></Divider>
          <Box
            className={`${IUUDashboardClasses['apply-btn-wrapper']} ${IUUDashboardClasses.bottomBoxStyle}`}
          >
            <Button
              className={IUUDashboardClasses.applyButtonStyle}
              onClick={onApply}
              disabled={
                selectedVesselName.length === 0 || selectedFleet.length === 0
              }
            >
              {intl.formatMessage({
                id: I18nKey.IUUDASHBOARD_APPLY,
              })}
            </Button>
            <Button
              className={IUUDashboardClasses.resetBoxStyle}
              onClick={onFilterReset}
              children={
                <>
                  <img height="17px" width="17px" src={reset} alt=""></img>
                  &nbsp;
                  {intl.formatMessage({
                    id: I18nKey.IUUDASHBOARD_RESET_TO_DEFAULT,
                  })}
                </>
              }
            />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
export default VMSFilters;
