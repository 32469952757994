import _ from 'lodash';
import moment from 'moment';
import { VESSEL_TYPE_COLORS } from '../../../constants/Constants';
import { dateToString } from '../../../utils/util';
import { vesselLayout } from '../../IUUDashboard/DashboardConfig';
import { Geojson } from '../../types';
import {
  trackDotsConfig,
  trackLineConfig,
  trackPointsTypeConfig,
} from './config';
import { vesselIcons } from './config';

export class VesselTrackingService {
  public static showVesselTracks(
    map: mapboxgl.Map | null,
    vessel: any,
    enableZoom?: boolean
  ) {
    const sortedVesselData = _.sortBy(vessel.trackData, function (dateObj) {
      return new Date(dateObj.date);
    });

    VesselTrackingService.addVesselTrackToMap(
      map,
      vessel,
      VesselTrackingService.getTrackPathJSON(sortedVesselData),
      VesselTrackingService.getTrackEndJSON(sortedVesselData, vessel),
      VesselTrackingService.getTrackPointJSON(sortedVesselData),
      enableZoom
    );
  }

  public static getTrackPointJSON = (sortedVesselData: any) => {
    const vesselPointData: Geojson = {
      type: 'FeatureCollection',
      features: [],
    };

    sortedVesselData.forEach((data: any) => {
      let point = {
        type: 'Feature',
        properties: {
          date: data.date,
          name: data.vesselName,
          vesselId: data.vesselId,
        },
        geometry: {
          type: 'Point',
          coordinates: [data.longitude, data.latitude],
        },
      };

      vesselPointData.features.push(point);
    });

    return vesselPointData;
  };

  public static getTrackPathJSON = (sortedVesselData: any) => {
    const latlon: number[][] = [];
    sortedVesselData.map((data: any) => {
      const values: string[] = data.positions.split(',');
      const position = sortedVesselData.findIndex(
        (d: any) => d.positions === data.positions
      );
      data.pointType =
        position === 0
          ? trackPointsTypeConfig.start
          : position === sortedVesselData.length - 1
          ? trackPointsTypeConfig.end
          : trackPointsTypeConfig.middle;
      latlon.push([parseFloat(values[1]), parseFloat(values[0])]);
    });
    const vesselPathData: Geojson = {
      type: 'FeatureCollection',
      features: [],
    };
    vesselPathData.features.push({
      type: 'Feature',
      properties: {},
      geometry: { type: 'LineString', coordinates: latlon },
    });
    return vesselPathData;
  };

  public static getTrackEndJSON(sortedVesselData: any, vessel: any) {
    const vesselPointData: Geojson = {
      type: 'FeatureCollection',
      features: [],
    };
    if (
      sortedVesselData[sortedVesselData.length - 1].latitude !==
        vessel.latitude &&
      sortedVesselData[sortedVesselData.length - 1].longitude !==
        vessel.longitude
    ) {
      vesselPointData.features.push({
        type: 'Feature',
        properties: {
          vesselID: vessel.vesselId,
          icon: vesselIcons[vessel.source],
          color: VESSEL_TYPE_COLORS['Track End'],
          course:
            sortedVesselData[sortedVesselData.length - 1].otherDetails?.cog ||
            0,
        },
        geometry: {
          type: 'Point',
          coordinates: [
            sortedVesselData[sortedVesselData.length - 1].longitude,
            sortedVesselData[sortedVesselData.length - 1].latitude,
          ],
        },
      });
    }
    return vesselPointData;
  }

  public static addVesselTrackToMap(
    map: mapboxgl.Map | null,
    vessel: any,
    pathData: any,
    endPointData: any,
    pointData: any,
    enableZoom?: boolean
  ) {
    VesselTrackingService.addVesselTrackLayer(map, vessel, pathData, pointData);
    VesselTrackingService.addTrackEndVesselLayer(map, vessel, endPointData);
  }

  public static addVesselTrackLayer(
    map: mapboxgl.Map | null,
    vessel: any,
    pathData: any,
    pointData: any
  ) {
    map?.addSource(vessel.vesselId + 'line', {
      type: 'geojson',
      data: pathData,
    });
    map?.addLayer({
      id: vessel.vesselId + 'line',
      type: 'line',
      source: vessel.vesselId + 'line',
      paint: trackLineConfig,
    });
    const pointsData = Object.assign({}, pointData);
    //Following code removes the last point so that it does not overlap the current position of the vessel
    pointsData.features.splice(pointsData.features.length - 1, 1);

    map?.addSource(vessel.vesselId + 'dots', {
      type: 'geojson',
      data: pointsData,
    });
    map?.addLayer({
      id: vessel.vesselId + 'dots',
      type: 'circle',
      source: vessel.vesselId + 'dots',
      paint: trackDotsConfig,
    });
  }

  public static addTrackEndVesselLayer(
    map: mapboxgl.Map | null,
    vessel: any,
    pointData: any
  ) {
    if (map && pointData.features.length) {
      if (!map?.hasImage(vessel.vesselId + 'position')) {
        map?.loadImage(vesselIcons[vessel.source], (error: any, image: any) => {
          if (error) throw error;
          image &&
            map?.addImage(vessel.vesselId + 'position', image, { sdf: true });
        });
      }
      map.addSource(vessel.vesselId + 'position', {
        type: 'geojson',
        data: pointData,
      });
      map.addLayer({
        id: vessel.vesselId + 'position',
        type: 'symbol',
        source: vessel.vesselId + 'position',
        layout: vesselLayout,
        paint: {
          'icon-color': { type: 'identity', property: 'color' },
        },
      });

      map?.setLayoutProperty(vessel.vesselId + 'position', 'icon-rotate', {
        type: 'identity',
        property: 'course',
      });
      map?.setLayoutProperty(
        vessel.vesselId + 'position',
        'icon-image',
        vessel.vesselId + 'position'
      );
    }
  }

  public static turnOffVesselTracks = (
    map: mapboxgl.Map | null,
    vesselList: any,
    setVesselList: any
  ) => {
    vesselList.forEach((vessel: any) => {
      vessel.showTracks = false;
    });
    setVesselList(vesselList);
    VesselTrackingService.removeTrackData(map, vesselList);
  };

  public static removeTrackData(map: mapboxgl.Map | null, vesselList: any) {
    vesselList.forEach((vessel: any) => {
      vessel.trackData = [];
      if (map) {
        VesselTrackingService.removeLayerSource(map, vessel.vesselId + 'line', [
          vessel.vesselId + 'line',
        ]);
        VesselTrackingService.removeLayerSource(map, vessel.vesselId + 'dots', [
          vessel.vesselId + 'dots',
        ]);
        VesselTrackingService.removeLayerSource(
          map,
          vessel.vesselId + 'position',
          [vessel.vesselId + 'position']
        );
      }
    });
  }

  public static removeLayerSource(
    map: mapboxgl.Map,
    sourceId: string,
    layerList: string[]
  ) {
    if (map && map.getSource(sourceId)) {
      layerList.forEach((l) => {
        VesselTrackingService.removeLayer(map, l);
      });
      map.removeSource(sourceId);
    }
  }

  public static removeLayer(map: mapboxgl.Map, layerId: string) {
    if (map?.getLayer(layerId)) map.removeLayer(layerId);
  }

  public static getTrackDates = (dataDuration: number) => {
    const toDate = moment();
    const fromDate = moment(toDate);
    fromDate.set('day', toDate.day() - dataDuration);
    return {
      toDate: dateToString(toDate, 'YYYYMMDDHHmmss', true),
      fromDate: dateToString(fromDate, 'YYYYMMDDHHmmss', true),
    };
  };
}
