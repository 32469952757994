import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { useStyles } from '../../../../utils/util';
import closeIcon from '../../../../assets/icons/close.svg';
import { PopUpStyles } from './VesselPopUpStyles';

const Value = styled(Paper)(PopUpStyles.ValueStyle);

const DeletePopUp: React.FC<any> = ({
  featureData,
  setShowDeletePopup,
  position,
  setConfirmDelete,
  isShowPopUp,
}) => {
  const intl = useIntl();
  const classes = useStyles(PopUpStyles, [])();
  const [isVisible, setIsVisible] = useState(isShowPopUp);
  const popupRef = useRef<any>();

  const onClickDelete = (e: any) => {
    e.stopPropagation();
    setConfirmDelete(true);
  };

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible]);

  const handleClickOutside = (event: any) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsVisible(false);
      onClose();
    }
  };

  const onClose = () => {
    setShowDeletePopup({
      display: false,
      featureData: {},
      position: {},
    });
  };

  return (
    <Box
      id="delete-popup"
      sx={{
        position: 'fixed',
        backgroundColor: 'black',
        borderRadius: 3,
        width: 'auto',
        top: position.top,
        left: position.left,
      }}
      style={{ display: isVisible ? 'inline-block' : 'none' }}
      ref={popupRef}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 8,
          padding: 0.5,
          color: 'white',
        }}
      >
        <img
          src={closeIcon}
          onClick={onClose}
          className={classes.closeIcon}
          alt="x"
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          pt: 1,
        }}
      >
        <IconButton onClick={onClickDelete}>
          <img
            alt="delete"
            style={{
              color: 'white',
            }}
            src={require('../../../../assets/icons/delete.svg').default}
          />
          <Typography sx={{ ml: 1, color: 'white' }}>
            {/* {intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_GEOFENCE_DELETE,
              })} */}
            Delete shape
          </Typography>
        </IconButton>
      </Box>
    </Box>
  );
};

export default DeletePopUp;
