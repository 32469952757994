import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import mapboxgl from 'mapbox-gl';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../../translations/I18nKey';
import { DataTable } from '../../../../components/DataTable/DataTable';
import {
  actionButtonsEnum,
  DataTableConfigType,
} from '../../../../components/DataTable/DataTableTypes';
import { TripsStyles } from '../AnalysisStyles';
import { FeaturesContext } from '../../FeaturesContext';
import { MapContext } from '../../MapContext';
import { getCourse, getDistance } from '../../HelperService';
import './Trips.scss';
import { useContextSelector } from 'use-context-selector';

const convertToDecimal = (coordinateDMS: string) => {
  const coordinateVal = coordinateDMS.split('.');
  const coordinateDecimal =
    parseInt(coordinateVal[1]) / 60 + parseInt(coordinateVal[2]) / 3600;
  return parseInt(coordinateDMS) < 0
    ? -Math.abs(coordinateDecimal + Math.abs(parseInt(coordinateVal[0])))
    : coordinateDecimal + Math.abs(parseInt(coordinateVal[0]));
};

export const TripsCRUD: React.FC<any> = ({
  setTripsEditMode,
  saveTripsData,
  saveTripChanges,
  setSaveTripsChanges,
}) => {
  const intl = useIntl();
  const deletePointRef = useRef(false);
  const removePointRef = useRef(0);
  const removeIdRef = useRef('');
  const totalMarkersRef = useRef(1);
  const countRef = useRef(1);
  const orderRef = useRef('');
  const wayPointRef = useRef<Record<string, any>[]>([{}]);
  const markersRef = useRef(new Map<string, mapboxgl.Marker>());
  const map = useContextSelector(MapContext, (state) => state.map);
  const displayConfig = useContextSelector(
    FeaturesContext,
    (state) => state.displayConfig
  );
  const [points, setPoints] = useState<any[]>([]);
  const [nameError, setNameError] = useState<boolean>(false);
  const [tripGeojson, setTripGeojson] = useState<any>({
    type: 'FeatureCollection',
    features: [] as any,
  });
  const [lineStringJson, setLineStringJson] = useState<any>({
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [] as any,
    },
    properties: {},
  });
  const [tripName, setTripName] = useState<string>('');
  const [tripComments, setTripComments] = useState<string>('');
  const [drawMode, setDrawMode] = useState(false);
  const pointsRef = useRef<any>(null);
  const newRowIdRef = useRef<any>(null);

  useEffect(() => {
    pointsRef.current = points;
  }, [points]);

  useEffect(() => {
    if (saveTripChanges) {
      setSaveTripsChanges(false);
      if (tripName.length <= 0) {
        setNameError(true);
        return;
      } else if (points.length === 0) {
        return;
      }
      const dataPoints = points.map((element: any, index: number) => {
        return { [index]: element };
      });
      const tripData = {
        tripId: tripName,
        points: dataPoints,
        comments: tripComments,
      };
      saveTripsData(tripData);
    }
  }, [saveTripChanges]);

  const checkIfValidLatitudenLongitude = (name: string, value: string) => {
    const sLat = value.split('.');
    if (name === 'lat') {
      if (
        !sLat[0] ||
        sLat[0] === '-' ||
        parseFloat(sLat[0]) < -90 ||
        parseFloat(sLat[0]) > 90
      )
        return false;
    } else {
      if (
        !sLat[0] ||
        sLat[0] === '-' ||
        parseFloat(sLat[0]) < -180 ||
        parseFloat(sLat[0]) > 180
      )
        return false;
    }
    if (!sLat[1] || parseFloat(sLat[1]) < 0 || parseFloat(sLat[1]) > 59)
      return false;
    if (!sLat[2] || parseFloat(sLat[2]) < 0 || parseFloat(sLat[2]) > 59)
      return false;
    return true;
  };

  const onAddNewRow = () => {
    let max = 0;
    max =
      Math.max(...pointsRef.current.map((o: any) => parseInt(o.order)), max) +
      1;
    setPoints([
      ...pointsRef.current,
      { order: max.toString(), lat: '', lon: '', distance: 0, course: 0 },
    ]);
    newRowIdRef.current = max;
  };

  const onDeleteRow = (params: GridCellParams) => {
    if (markersRef.current.get(params.row.order)) {
      deletePointRef.current = true;
      removeIdRef.current = params.row.order;
      addRemoveWP(
        convertToDecimal(params.row.lat),
        convertToDecimal(params.row.lon)
      );
    } else {
      const data = pointsRef.current.filter(
        (element: any) => element.order !== params.row.order
      );
      data.forEach((element: any) => {
        if (parseInt(element.order) > parseInt(params.row.order)) {
          element.order = (parseInt(element.order) - 1).toString();
        }
      });
      setPoints([...data]);
    }
  };

  const onCellEdit = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    params: GridCellParams
  ) => {
    let data = [...pointsRef.current];
    data = data.map((element: any) => {
      if (element.order === params.row.order) {
        return { ...element, [event.target.name]: event.target.value };
      } else {
        return element;
      }
    });
    let lat = params.row.lat;
    let lon = params.row.lon;

    if (event.target.name === 'lat') {
      lat = event.target.value;
    } else {
      lon = event.target.value;
    }

    if (
      checkIfValidLatitudenLongitude('lat', lat) &&
      checkIfValidLatitudenLongitude('lon', lon)
    ) {
      if (params.row.order === newRowIdRef.current.toString()) {
        if (pointsRef.current.length === 1) {
          if (!drawMode) loadInitialSettings();
          drawWaypoints();
        }
        addRemoveWP(convertToDecimal(lat), convertToDecimal(lon));
      } else {
        onDrag(
          convertToDecimal(lat),
          convertToDecimal(lon),
          parseInt(params.row.order)
        );
      }
    } else {
      setPoints(data);
    }
  };

  const customInputField = (params: GridCellParams, name: string) => {
    return (
      <Box sx={{ background: 'black' }}>
        <TextField
          id="outlined-required"
          value={params.row[name]}
          name={name}
          autoComplete="off"
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            onCellEdit(event, params);
          }}
        />
      </Box>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'order',
      width: 60,
      headerName: intl.formatMessage({
        id: I18nKey.ANALYSIS_TRIPS_ORDER,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
    },
    {
      field: 'lat',
      width: 100,
      headerName: intl.formatMessage({
        id: I18nKey.ANALYSIS_TRIPS_LATITUDE,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return customInputField(params, 'lat');
      },
    },
    {
      field: 'lon',
      width: 100,
      headerName: intl.formatMessage({
        id: I18nKey.ANALYSIS_TRIPS_LONGITUDE,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return customInputField(params, 'lon');
      },
    },
    {
      field: 'distance',
      width: 110,
      headerName: intl.formatMessage({
        id: I18nKey.ANALYSIS_DISTANCE,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
    },
    {
      field: 'course',
      width: 110,
      headerName: intl.formatMessage({
        id: I18nKey.ANALYSIS_TRIPS_BEARING,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
    },
  ];

  const tripsListDataTableConfig: DataTableConfigType = {
    gridHeight: '360px',
    disableFooter: true,
    id: 'order',
    actions: {
      showActions: true,
      width: 50,
      minWidth: 50,
      flex: 1,
      actionButtons: [
        {
          name: actionButtonsEnum.delete,
          position: 1,
          onClick: onDeleteRow,
        },
      ],
      actionColumnHeaderClassName: 'tableHeaderStyle',
      actionColumnCellClassName: 'tableCellStyle',
    },
    addNewRow: {
      allowAdd: true,
      newRowFormat: [],
      onAddNewRow,
    },
  };

  useEffect(() => {
    return () => {
      clearTrip();
    };
  }, [displayConfig?.data?.analysis.items.tripData.selected]);

  const clearTrip = () => {
    map?.off('dblclick', onDblClick);
    markersRef.current.forEach((item) => item.remove());
    markersRef.current.clear();
    if (map?.getSource('geojson')) {
      map?.removeLayer('measure-lines');
      map?.removeLayer('measure-points');
      map?.removeSource('geojson');
    }
    totalMarkersRef.current = 1;
    countRef.current = 1;
    orderRef.current = '';
    wayPointRef.current = [{}];
  };

  const onTripNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setTripName(event.target.value);
    setNameError(false);
  };

  const onTripCommentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setTripComments(event.target.value);
  };

  const onClickDraw = () => {
    loadInitialSettings();
    drawWaypoints();
  };

  const drawWaypoints = () => {
    map?.on('dblclick', onDblClick);
    setDrawMode(true);
  };

  const loadInitialSettings = () => {
    map?.addSource('geojson', {
      type: 'geojson',
      data: tripGeojson,
    });
    map?.addLayer({
      id: 'measure-lines',
      type: 'line',
      source: 'geojson',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': 'black',
        'line-width': 2.5,
      },
      filter: ['in', '$type', 'LineString'],
    });
    map?.addLayer({
      id: 'measure-points',
      type: 'circle',
      source: 'geojson',
      paint: {
        'circle-radius': 5,
        'circle-color': '#fff',
        'circle-stroke-color': 'blue',
        'circle-stroke-width': 2,
      },
      filter: ['in', '$type', 'Point'],
    });
  };

  const onDblClick = (event: any) => {
    addRemoveWP(event.lngLat.lat, event.lngLat.lng, event.point);
  };

  const addRemoveWP = (lat: number, lon: number, point?: any) => {
    if (displayConfig?.data?.analysis.items.tripData.selected) {
      let eventPoint;
      if (point) {
        eventPoint = point;
      } else {
        eventPoint = new mapboxgl.Point(lon, lat);
      }
      const features = map?.queryRenderedFeatures(eventPoint, {
        layers: ['measure-points'],
      });
      let tripJson = tripGeojson;
      if (tripJson.features.length > 1) {
        tripJson.features.pop();
        setTripGeojson({ ...tripJson });
      }
      togglePoint(features, lat, lon);
      let tripfeatures = tripGeojson;
      if (tripGeojson.features.length > 1) {
        let lineString = lineStringJson;
        lineString.geometry.coordinates = tripGeojson.features.map(function (
          point: any
        ) {
          return point.geometry?.coordinates;
        });
        setLineStringJson(lineString);

        tripfeatures.features.push(lineString);
        setTripGeojson({ ...tripfeatures });
      }

      tripDataTableUpdate(lat, lon);

      updateDistance();
      (map?.getSource('geojson') as mapboxgl.GeoJSONSource).setData(
        tripfeatures
      );
    }
  };

  const tripDataTableUpdate = (lat: number, lon: number) => {
    if (removePointRef.current === 1) {
      removePointRef.current = 0;
    } else {
      wayPointRef.current = wayPointRef.current.filter((pt) => pt.order);
      wayPointRef.current.push({
        lat: convertToDMS(lat),
        lon: convertToDMS(lon),
        distance: 0,
        order: orderRef.current,
        course: 0,
      });
      setPoints(wayPointRef.current);
    }
  };

  const togglePoint = (features: any, lat: number, lon: number) => {
    if (features?.length || deletePointRef.current === true) {
      let id: string;
      if (deletePointRef.current === true) {
        id = removeIdRef.current;
      } else {
        id = features[0].properties?.id;
      }
      let geoJsonFeatures = tripGeojson;
      geoJsonFeatures.features = geoJsonFeatures.features.filter(
        (point: any) => {
          return point.properties?.id !== id;
        }
      );
      geoJsonFeatures.features.forEach((pt: any) => {
        if (parseInt(pt.properties?.id) > parseInt(id))
          pt.properties.id = (parseInt(pt.properties?.id) - 1).toString();
      });
      countRef.current = countRef.current - 1;
      setTripGeojson({ ...geoJsonFeatures });
      deletePointRef.current = false;
      removeIdRef.current = '-1';
      markersRef.current.get(id)?.remove();
      markersRef.current.delete(id);
      let localMarker = new Map(markersRef.current);
      localMarker.forEach((value: mapboxgl.Marker, key) => {
        if (parseInt(key) > parseInt(id)) {
          value.getElement().childNodes[0].childNodes[0].childNodes[2].textContent =
            String(parseInt(key) - 1);
          markersRef.current.delete(key);
          markersRef.current.set(String(parseInt(key) - 1), value);
        }
      });
      localMarker.clear();
      wayPointRef.current = wayPointRef.current.filter((pt) => pt.order != id);
      wayPointRef.current.forEach((pt) => {
        if (parseInt(pt.order) > parseInt(id))
          pt.order = (parseInt(pt.order) - 1).toString();
      });
      setPoints(wayPointRef.current);
      removePointRef.current = 1;
    } else {
      totalMarkersRef.current = markersRef.current.size + 1;
      countRef.current = countRef.current + 1;
      const el = createPoint(totalMarkersRef.current++);
      const marker = new mapboxgl.Marker({
        element: el,
        draggable: true,
      }).setLngLat([lon, lat]);
      setMarkerDrag(marker);
      if (map) {
        if (countRef.current === 2) {
          marker.getElement().childNodes[0].childNodes[1].remove();
        }
        marker.addTo(map);
      }

      const id = countRef.current - 1 + '';
      const point: any = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lon, lat],
        },
        properties: {
          id: id,
        },
      };
      orderRef.current = id;
      let geoJsonFeatures = tripGeojson;
      geoJsonFeatures.features.push(point);
      setTripGeojson({ ...geoJsonFeatures });
      markersRef.current.set(id, marker);
      newRowIdRef.current = -1;
    }
  };

  const setMarkerDrag = (marker: mapboxgl.Marker) => {
    marker.on('drag', (e: any) => {
      onDrag(
        e.target._lngLat.lat,
        e.target._lngLat.lng,
        e.target.getElement().childNodes[0].childNodes[0].childNodes[2]
          .textContent
      );
    });
  };

  const onDrag = (lat: number, lon: number, index: number) => {
    markersRef.current
      .get(tripGeojson.features[index - 1].properties.id)
      ?.setLngLat([lon, lat]);
    let geojson = tripGeojson;
    geojson.features[index - 1].geometry.coordinates[0] = lon;
    geojson.features[index - 1].geometry.coordinates[1] = lat;
    setTripGeojson({ ...tripGeojson });
    (map?.getSource('geojson') as mapboxgl.GeoJSONSource).setData(tripGeojson);
    updateDistance();
    wayPointRef.current[index - 1].lat = convertToDMS(lat);
    wayPointRef.current[index - 1].lon = convertToDMS(lon);
    setPoints([...wayPointRef.current]);
  };

  const convertToDMS = (coordinate: number) => {
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = Math.floor((minutesNotTruncated - minutes) * 60);
    const DMS =
      coordinate >= 0
        ? degrees + '.' + minutes + '.' + seconds
        : '-' + degrees + '.' + minutes + '.' + seconds;
    return DMS;
  };

  const updateDistance = () => {
    let totalDistance = 0;
    let i = 0;
    markersRef.current.forEach((value: mapboxgl.Marker) => {
      if (i === 0) {
        i++;
        value.getElement().childNodes[0].childNodes[1]?.remove();
        wayPointRef.current[i - 1].distance = 0;
        wayPointRef.current[i - 1].course = 0;
        setPoints(wayPointRef.current);
        return;
      }
      const distance = getDistance(
        lineStringJson.geometry.coordinates[i - 1][1],
        lineStringJson.geometry.coordinates[i - 1][0],
        lineStringJson.geometry.coordinates[i][1],
        lineStringJson.geometry.coordinates[i][0],
        'N'
      );

      const course = getCourse(
        lineStringJson.geometry.coordinates[i - 1][1],
        lineStringJson.geometry.coordinates[i - 1][0],
        lineStringJson.geometry.coordinates[i][1],
        lineStringJson.geometry.coordinates[i][0]
      );
      value.getElement().childNodes[0].childNodes[1].textContent = `Distance: ${distance}nm\nCourse: ${course}°`;
      i++;
      totalDistance += distance;
      if (i === markersRef.current.size) {
        value.getElement().childNodes[0].childNodes[1].textContent = `Distance: ${distance}nm\nCourse: ${course}°\nTotal: ${
          Math.round(totalDistance * 10) / 10
        }nm`;
      }
      wayPointRef.current[i - 1]['distance'] = distance;
      wayPointRef.current[i - 1]['course'] = course;
    });
    setPoints(wayPointRef.current);
  };

  const createPoint = (count: number) => {
    const r = 8;
    const r0 = Math.round(r * 0.6);
    const w = r * 2;

    const html = `<div class="tooltip"><svg width="${w}" height="${w}"  text-anchor="middle"  style="font: 8px sans-serif; display: block"><circle cx="${r}" cy="${r}" r="${
      r0 + 2
    }" fill="blue" /><circle cx="${r}" cy="${r}" r="${r0}" fill="white" /><text dominant-baseline="central" transform="translate(${r},${r})">${count}</text></svg><pre class="tooltiptext"></pre></div>`;

    const el = document.createElement('div');
    el.innerHTML = html;
    return el;
  };

  return (
    <Box
      sx={{
        color: 'white',
        overflowX: 'hidden',
        height: '340px',
        minHeight: 'auto',
        overflowY: 'scroll',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          my: 1,
          mx: 2,
        }}
      >
        <Typography>
          {intl.formatMessage({
            id: I18nKey.ANALYSIS_CREATE_TRIP,
          })}
        </Typography>
        <Link
          sx={{
            color: '#6F6F6F',
            cursor: 'pointer',
            zIndex: 5,
          }}
          underline="none"
          onClick={() => setTripsEditMode(false)}
        >
          {intl.formatMessage({
            id: I18nKey.ANALYSIS_TRIPS_BACK_TO_TRIP_LIST,
          })}
        </Link>
      </Box>
      <Box sx={{ mx: 2 }}>
        <Grid container columnSpacing={2} sx={{ width: 'auto' }}>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
                {intl.formatMessage({
                  id: I18nKey.ANALYSIS_TRIPS_NAME,
                })}
              </Typography>
              <TextField
                error
                id="trip-name-input"
                defaultValue={tripName}
                sx={TripsStyles.tripName}
                onChange={onTripNameChange}
                helperText={nameError && 'Name is required'}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
                {intl.formatMessage({
                  id: I18nKey.ANALYSIS_TRIPS_COMMENTS,
                })}
              </Typography>
              <TextField
                id="trip-name-input"
                defaultValue={tripComments}
                sx={TripsStyles.tripName}
                onChange={onTripCommentChange}
                placeholder={intl.formatMessage({
                  id: I18nKey.ANALYSIS_TRIPS_COMMENTS_HERE,
                })}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          m: 2,
          mt: 3,
          '& .MuiTypography-root': { color: 'white' },
        }}
      >
        <Typography>
          {intl.formatMessage({
            id: I18nKey.ANALYSIS_WAYPOINTS,
          })}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <IconButton onClick={onClickDraw} sx={{ zIndex: 1 }}>
            <img
              alt="draw"
              style={{
                filter: drawMode
                  ? TripsStyles.drawButton.active
                  : TripsStyles.drawButton.inActive,
              }}
              src={require('../../../../assets/icons/draw.svg').default}
            />
            <Typography sx={{ ml: 1 }}>
              {intl.formatMessage({
                id: I18nKey.ANALYSIS_TRIPS_DRAW,
              })}
            </Typography>
          </IconButton>
          <Typography
            sx={{
              color: '#D1D1D1 !important',
              fontSize: '14px',
              fontStyle: 'italic',
            }}
          >
            {intl.formatMessage({
              id: I18nKey.ANALYSIS_TRIPS_DRAW_HELP,
            })}
          </Typography>
        </Box>
      </Box>
      <Box sx={[TripsStyles.dataTableStyles, { mt: -2 }]}>
        <DataTable
          tableColumns={columns}
          dataRows={points}
          tableConfig={tripsListDataTableConfig}
        />
      </Box>
    </Box>
  );
};
