export const popupFooterStyles: any = {
  footer: {
    height: '65px',
    backgroundColor: '#5c5c5c',
    position: 'absolute',
    bottom: '0',
    width: '100%',
    borderRadius: '0px 0px 12px 12px',
  },
  saveLink: {
    color: '#DDD6D6',
    textTransform: 'none',
    textDecorationColor: '#DDD6D6',
    fontSize: '14px',
    marginLeft: '27px',
    marginTop: '21px',
  },
  resetLink: {
    color: '#DDD6D6 !important',
    textTransform: 'none !important',
    textDecorationColor: 'none !important',
    fontSize: '14px !important',
    maxWidth: '123px !important',
    height: '17px !important',
    marginTop: '21px !important',
    float: 'right !important',
    marginRight: '4px !important',
  },
  resetImage: {
    marginTop: '2px',
    marginRight: '3px',
    width: '17px',
    height: '17px',
    filter: 'brightness(0) invert(1)',
  },
  actionButton: {
    backgroundColor: 'orange !important',
    color: 'black !important',
    borderRadius: '9px !important',
    width: '112px !important',
    height: '38px !important',
    marginTop: '11px !important',
    marginRight: '19px !important',
    '&:hover': {
      backgroundColor: 'orange !important',
      color: 'black !important',
    },
    '&:disabled': {
      backgroundColor: '#b88832 !important',
      color: 'black !important',
    },
  },
};
