import React, { useEffect, useState } from 'react';
import { createContext } from 'use-context-selector';
import UserClient from '../../api/userAPIs';
import { getUserAttributes } from '../../utils/auth';
import {
  DEFAULT_PRIVATE_ROUTES,
  LICENSED_PRIVATE_ROUTES,
  Route,
} from '../Router/RouterConfig';

interface LicensedFeatures {
  fetchFeatures: any;
  features: string[];
  isLoading: boolean;
  licensedRoutes: Route[];
}

export const LicensedFeaturesContext = createContext<LicensedFeatures>({
  fetchFeatures: () => {},
  features: [],
  isLoading: false,
  licensedRoutes: [],
});

export const LicensedFeaturesContextProvider: React.FC = ({ children }) => {
  const [features, setFeatures] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [licensedRoutes, setLicensedRoutes] = useState<Route[]>([]);

  useEffect(() => {
    fetchLicensedFeatures();
  }, []);

  const fetchLicensedFeatures = async () => {
    try {
      const { userName = null, seastarId = null } = getUserAttributes();
      setIsLoading(true);
      const data = await UserClient.getLicensedFeatures(userName, seastarId);
      setFeatures(data);
      let licensedPrivateRoutes = DEFAULT_PRIVATE_ROUTES.concat(
        LICENSED_PRIVATE_ROUTES.filter((route) => {
          if (
            (route.path === '/fishcatch-dashboard' &&
              !data.includes('Fishcatch dashboard')) ||
            (route.path === '/tracking-dashboard' &&
              !data.includes('IUU dashboard'))
          )
            return false;
          else return true;
        })
      );
      setLicensedRoutes(licensedPrivateRoutes);
    } catch (error) {
      console.error('Error while fetching Licensed Features for user', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LicensedFeaturesContext.Provider
      value={{
        fetchFeatures: fetchLicensedFeatures,
        features: features,
        isLoading: isLoading,
        licensedRoutes: licensedRoutes,
      }}
    >
      {children}
    </LicensedFeaturesContext.Provider>
  );
};
