import { vesselLayout } from '../../IUUDashboard/DashboardConfig';
import { Geojson } from '../../types';

export class VesselBreachService {
  public static readbreachData(allVesselsData: any, breachData: any) {
    const breachVesselPositions: Geojson = {
      type: 'FeatureCollection',
      features: [],
    };
    if (allVesselsData) {
      allVesselsData.forEach((vessel: any) => {
        if (
          Object.keys(breachData)
            .map((d) => Number(d))
            .includes(Number(vessel.vesselId))
        ) {
          breachVesselPositions.features.push(
            VesselBreachService.getVesselPointGeojson(vessel)
          );
        }
      });
      //   Object.values(allVesselsData.data).forEach((vessels: any) => {
      // if (Object.keys(breachData).includes(vessels.vesselId)) {
      //   breachVesselPositions.features.push(
      //     VesselBreachService.getVesselPointGeojson(vessels)
      //   );
      // }
      //   });
    }
    return breachVesselPositions;
  }

  public static getVesselPointGeojson(vessel: any): any {
    return {
      type: 'Feature',
      properties: {
        vesselID: vessel.vesselId,
      },
      geometry: {
        type: 'Point',
        coordinates: [vessel.longitude, vessel.latitude],
      },
    };
  }

  public static addVesselBreachToMap(
    map: mapboxgl.Map | null,
    breachVesselDrawObject: any
  ) {
    if (map) {
      if (!map?.hasImage('breachImage')) {
        map?.loadImage(
          breachVesselDrawObject.breachIcon,
          (error: any, image: any) => {
            if (error) throw error;
            image && map?.addImage('breachImage', image, { sdf: true });
          }
        );
      }
      if (!map?.getSource(breachVesselDrawObject.layerId)) {
        map?.addSource(breachVesselDrawObject.layerId, {
          type: 'geojson',
          data: breachVesselDrawObject.source,
        });

        map?.addLayer({
          id: breachVesselDrawObject.layerId,
          type: 'symbol',
          source: breachVesselDrawObject.layerId,
          layout: vesselLayout,
          paint: {
            'icon-color': 'red',
          },
        });
        map?.setLayoutProperty(
          breachVesselDrawObject.layerId,
          'icon-image',
          'breachImage'
        );
        map?.setLayoutProperty(
          breachVesselDrawObject.layerId,
          'visibility',
          'visible'
        );
      }
    }
  }
}
