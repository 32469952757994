import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  RadioGroup,
  TextField,
  Typography,
  IconButton,
  Button,
  Chip,
  Select,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import { floor, isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { ColorBox } from '../../../../../components/ColorBox/ColorBox';
import { DatePicker } from '../../../../../components/DateRangePicker/Calender';
import RadioButton from '../../../../../components/RadioButton/RadioButton';
import { Stepper } from '../../../../../components/Stepper/Stepper';
import { Toggle } from '../../../../../components/Toggle/Toggle';
import { I18nKey } from '../../../../../translations/I18nKey';
import * as IUUClient from '../../../../../api/iuuAPIs';
import { GeofenceCRUDStyles } from './GeofenceStyles';
import { GeofenceGeneralProps } from './GeofenceTypes';
import { getUserAttributes } from '../../../../../utils/auth';
import { IUUService } from '../../IUUService';
import { MapContext } from '../../../MapContext';
import { getPositionForPopUp, removeLayerSource } from '../../../HelperService';
import { FillAreaService } from '../../../Products/MapLayers/FillAreaService';
import { useContextSelector } from 'use-context-selector';
import { IControl } from 'mapbox-gl';
import { ProgressSpinner } from '../../../../../components/ProgressSpinner/ProgressSpinner';
import AllOutIcon from '@mui/icons-material/AllOutOutlined';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import { Geojson } from '../../../../types';
import DeletePopUp from '../../PopUp/DeletePopUp';

const precision = 1;
const Input = styled('input')({
  display: 'none',
});

export const GeofenceGeneral: React.FC<GeofenceGeneralProps> = ({
  isGeofenceEdit,
  geofenceGeneral,
  setGeofenceGeneral,
  setIsDisabledGeofenceSave,
  error,
  displayAlert,
}) => {
  const intl = useIntl();
  const userName = getUserAttributes().userName;
  const map = useContextSelector(MapContext, (state) => state.map);
  const [drawControl, setDrawControl] = useState<IControl>();
  const [file, setFile] = useState<File | null>(null);
  const [fileKey, setFileKey] = useState<string>('');
  const [alertRadioType, setAlertRadioType] = useState<string>('email');
  const [disableDrawingSave, setDisableDrawingSave] = useState<boolean>(true);
  const [drawMode, setDrawMode] = useState(false);
  const [drawModeValue, setDrawModeValue] = useState('');
  const [drawGeojson, setDrawGeojson] = useState<Geojson | any>({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState<Record<string, any>>({
    display: false,
    featureData: {},
  });
  const [clearAll, setClearAll] = useState(false);
  const [unselectMode, setUnselectMode] = useState(false);

  useEffect(() => {
    setIsDisabledGeofenceSave(drawMode);
  }, [drawMode]);

  useEffect(() => {
    if (
      geofenceGeneral.hasOwnProperty('url') &&
      isEmpty(drawGeojson) &&
      !clearAll
    ) {
      geofenceGeneral.url && getGeofenceGeojson(geofenceGeneral);
    }
  }, [geofenceGeneral]);

  useEffect(() => {
    if (confirmDelete) {
      deleteOnClick();
    }
  }, [confirmDelete]);

  useEffect(() => {
    return () => {
      drawControl && IUUService.disableDrawing(map, drawControl);
    };
  }, [drawControl]);

  useEffect(() => {
    if (clearAll) {
      setDrawGeojson(() => {});
      setGeofenceGeneral({
        ...geofenceGeneral,
        filedata: {},
      });
    }
  }, [clearAll]);

  const getGeofenceGeojson = async (fenceData: any) => {
    if (geofenceGeneral.data === 'upload') fetchGeofence(fenceData);
    else if (!clearAll) {
      fetchGeofence(fenceData);
    }
    setClearAll(false);
  };

  const fetchGeofence = async (fenceData: any) => {
    const data = await axios.get(fenceData.url);
    let json: Geojson = {
      type: 'FeatureCollection',
      features: data.data.features.map((d: any) => {
        return { ...d, properties: { ...d.properties, id: d.id } };
      }),
    };
    let fileName = fenceData?.gjsonFileName
      ? fenceData.gjsonFileName
      : fenceData?.filename;
    setGeofenceGeneral((prevState) => {
      return {
        ...prevState,
        filedata: json,
        uploadedFileName: fileName,
      };
    });
    setDrawGeojson(() => json);
    setLoading(false);
    map &&
      FillAreaService.addFillAreaLayer(
        map,
        'geofenceDrawing',
        json,
        true,
        {
          'fill-color': geofenceGeneral.display.fillColor,
          'fill-opacity': 0.5,
        },
        false,
        'geofence',
        true,
        {
          'line-color': geofenceGeneral.display.strokeColor,
          'line-width': 3,
        },
        () => {},
        () => {}
      );
  };

  useEffect(() => {
    if (geofenceGeneral.data === 'draw') {
      map?.on('click', 'geofenceDrawing', showPopup);
      return () => {
        map?.off('click', 'geofenceDrawing', showPopup);
      };
    }
  }, [map, drawGeojson]);

  useEffect(() => {
    setGeofenceGeneral((prevState) => {
      return {
        ...prevState,
        filedata: drawGeojson,
      };
    });
  }, [drawGeojson]);

  useEffect(() => {
    if (unselectMode) {
      onClickSaveDrawing();
      setUnselectMode(false);
    }
  }, [unselectMode]);

  const showPopup = (e: any) => {
    const divElement = document.getElementById('delete-popup');
    const elemHeight = divElement && divElement.clientHeight;
    const elemWidth = divElement && divElement.clientWidth;
    const point = map && map.project([e.lngLat.lng, e.lngLat.lat]);
    const featureData = e.features[0].properties;
    featureData['position'] = getPositionForPopUp({
      latitude: featureData.lat,
      longitude: featureData.lon,
    });
    if (point) {
      setShowDeletePopup({
        display: true,
        featureData: featureData,
        position: {
          top: point?.y - (elemHeight || 0),
          left: point?.x - (elemWidth || 0),
        },
      });
    }
  };
  const deleteOnClick = () => {
    // eslint-disable-next-line array-callback-return
    if (Object.keys(drawGeojson).length) {
      let modFeatures: any = drawGeojson.features.filter((feature: any) => {
        if (feature.properties.id !== showDeletePopup.featureData.id)
          return {
            type: 'Feature',
            geometry: feature.geometry,
            properties: feature.properties,
          };
      });
      const geojson: Geojson = {
        type: 'FeatureCollection',
        features: modFeatures,
      };

      (map?.getSource('geofenceDrawing') as any).setData(geojson);
      setDrawGeojson(() => geojson);
      setConfirmDelete(false);
      setShowDeletePopup({
        display: false,
        featureData: {},
        position: {},
      });
    }
  };

  const onToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGeofenceGeneral({ ...geofenceGeneral, active: event.target.checked });
  };

  const onTypeRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGeofenceGeneral({ ...geofenceGeneral, type: event.target.value });
  };

  const onStartDateChange = (dateSelected: Date | null) => {
    setGeofenceGeneral({
      ...geofenceGeneral,
      startDate: dateSelected ? dateSelected : new Date(),
    });
  };

  const onEndDateChange = (dateSelected: Date | null) => {
    setGeofenceGeneral({
      ...geofenceGeneral,
      endDate: dateSelected ? dateSelected : new Date(),
    });
  };

  const onDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGeofenceGeneral({
      ...geofenceGeneral,
      description: event.target.value,
    });
  };

  const onDataRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'upload') {
      onClickDeleteDrawing();
      setDrawGeojson(() => {});
      setGeofenceGeneral({
        ...geofenceGeneral,
        filedata: {},
      });
    }
    if (event.target.value === 'draw') {
      onFileDelete();
      setDrawGeojson(() => {});
    }
    setGeofenceGeneral({
      ...geofenceGeneral,
      data: event.target.value,
    });
  };

  const onAlertRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAlertRadioType((event.target as HTMLInputElement).value);
  };

  const onFillColorChange = (event: any) => {
    setGeofenceGeneral({
      ...geofenceGeneral,
      display: { ...geofenceGeneral.display, fillColor: event.hex },
    });
    IUUService.changeDisplayProperty(
      map,
      geofenceGeneral.display.strokeColor,
      event.hex,
      geofenceGeneral.display.fillWidth
    );
  };

  const onStrokeColorChange = (event: any) => {
    setGeofenceGeneral({
      ...geofenceGeneral,
      display: { ...geofenceGeneral.display, strokeColor: event.hex },
    });
    IUUService.changeDisplayProperty(
      map,
      event.hex,
      geofenceGeneral.display.fillColor,
      geofenceGeneral.display.fillWidth
    );
  };

  const onFillWidthChange = (e: any) => {
    setGeofenceGeneral({
      ...geofenceGeneral,
      display: {
        ...geofenceGeneral.display,
        fillWidth: floor(e.target.value, precision),
      },
    });
    IUUService.changeDisplayProperty(
      map,
      geofenceGeneral.display.strokeColor,
      geofenceGeneral.display.fillColor,
      floor(e.target.value, precision)
    );
  };

  const onEmailIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGeofenceGeneral({
      ...geofenceGeneral,
      email: e.target.value,
    });
  };

  const onFileChange = (e: any) => {
    setFileKey(Math.random().toString(36));
    setFile(e.target.files[0]);
    map &&
      removeLayerSource(map, 'geofenceDrawing', [
        'geofenceDrawing',
        'geofenceDrawingoutline',
      ]);
  };

  const onFileDelete = () => {
    setFileKey(Math.random().toString(36));
    setFile(null);
    setGeofenceGeneral({
      ...geofenceGeneral,
      filedata: null,
      uploadedFileName: '',
    });
    map &&
      removeLayerSource(map, 'geofenceDrawing', [
        'geofenceDrawing',
        'geofenceDrawingoutline',
      ]);
  };

  const onFileUpload = () => {
    if (file) {
      let formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      uploadShapeFile(userName, formData, setLoading).then((data) => {
        data && getGeofenceGeojson(data);
      });
    }
  };

  const uploadShapeFile = async (
    userName: string,
    file: any,
    setLoading: any
  ) => {
    try {
      const data = await IUUClient.uploadShapeFile(userName, file);
      displayAlert(I18nKey.IUU_SETTINGS_FILE_UPLOAD_SUCCESS, 'success');
      return data;
    } catch (error: any) {
      setLoading(false);
      if (error.response.status === 422) {
        displayAlert(error.response.data.detail, 'error', true);
      } else {
        displayAlert(I18nKey.IUU_SETTINGS_GEOFENCE_UPLOAD_ERROR_MESSAGE);
      }
      console.error(error.response);
    }
  };

  const onClickDraw = (type: string) => {
    setDisableDrawingSave(false);
    setDrawMode(true);
    IUUService.drawShape(
      map,
      type,
      drawControl,
      setDrawControl,
      drawGeojson,
      setUnselectMode
    );
  };

  const onClickSaveDrawing = () => {
    const features = IUUService.savePolygon(
      map,
      drawControl,
      geofenceGeneral.display
    );
    setDrawMode(false);
    setDrawGeojson(features);
    setGeofenceGeneral({
      ...geofenceGeneral,
      filedata: features,
    });
    setDrawModeValue('');
  };

  const onClickDeleteDrawing = () => {
    map &&
      removeLayerSource(map, 'geofenceDrawing', [
        'geofenceDrawing',
        'geofenceDrawingoutline',
      ]);
    drawControl && IUUService.disableDrawing(map, drawControl);
    setDrawModeValue('');
    setDrawGeojson(() => {});
    setClearAll(true);
    setDrawMode(false);
  };

  return (
    <>
      {showDeletePopup.display && (
        <Box sx={{ position: 'fixed' }}>
          <DeletePopUp
            featureData={showDeletePopup.featureData}
            setShowDeletePopup={setShowDeletePopup}
            position={showDeletePopup.position}
            setConfirmDelete={setConfirmDelete}
            isShowPopUp={showDeletePopup.display}
          ></DeletePopUp>
        </Box>
      )}
      <Box>
        <Divider sx={{ borderColor: '#333333', my: 2 }} flexItem />
        <Grid container spacing={18}>
          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography sx={{ mb: 1 }}>
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_TYPE,
                })}
              </Typography>
              <Box sx={{ ml: '1%' }}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="style"
                    name="style-radio-buttons-group"
                    value={geofenceGeneral.type}
                    onChange={onTypeRadioChange}
                  >
                    <FormControlLabel
                      value="alert"
                      control={<RadioButton />}
                      label={intl.formatMessage({
                        id: I18nKey.IUU_SETTINGS_GEOFENCE_ALERT,
                      })}
                    />
                    <FormControlLabel
                      value="safe"
                      control={<RadioButton />}
                      label={intl.formatMessage({
                        id: I18nKey.IUU_SETTINGS_GEOFENCE_SAFE,
                      })}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography sx={{ mb: 1 }}>
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_STATUS,
                })}
              </Typography>
              <FormGroup>
                <FormControlLabel
                  sx={{
                    color: '#fff',
                    marginLeft: '0px',
                  }}
                  control={
                    <Toggle
                      checked={geofenceGeneral.active}
                      sx={{ marginLeft: 0 }}
                      onChange={onToggleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={intl.formatMessage({
                    id: I18nKey.IUU_SETTINGS_GEOFENCE_ACTIVE,
                  })}
                  labelPlacement="end"
                />
              </FormGroup>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: '#333333', my: 2 }} flexItem />
        <Grid container spacing={18}>
          <Grid item xs={6}>
            <Box sx={GeofenceCRUDStyles.datePicker}>
              <Typography>
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_START_DATE,
                })}
              </Typography>
              <DatePicker
                defaultSelection={geofenceGeneral.startDate}
                onChange={onStartDateChange}
                format="MMM dd, yyyy"
                textColor="#fff"
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={GeofenceCRUDStyles.datePicker}>
              <Typography>
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_END_DATE,
                })}
              </Typography>
              <DatePicker
                defaultSelection={geofenceGeneral.endDate}
                onChange={onEndDateChange}
                format="MMM dd, yyyy"
                textColor="#fff"
              />
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: '#333333', my: 2 }} flexItem />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ fontSize: '14px', color: '#6F6F6F' }}>
            {intl.formatMessage({
              id: I18nKey.IUU_SETTINGS_GEOFENCE_DESCRIPTION,
            })}
          </Typography>
          <TextField
            id="geofence-description-input"
            placeholder={intl.formatMessage({
              id: I18nKey.IUU_SETTINGS_GEOFENCE_DESCRIPTION_PLACEHOLDER,
            })}
            value={geofenceGeneral.description}
            onChange={onDescriptionChange}
            multiline
            rows={3}
            sx={GeofenceCRUDStyles.geofenceName}
          />
        </Box>
        <Divider sx={{ borderColor: '#333333', my: 2 }} flexItem />
        <Grid container columnSpacing={6} rowSpacing={2}>
          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography sx={{ mb: 1 }}>
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_DATA,
                })}
              </Typography>
              <Box sx={{ ml: '1%' }}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="style"
                    name="style-radio-buttons-group"
                    value={geofenceGeneral.data}
                    onChange={onDataRadioChange}
                  >
                    <FormControlLabel
                      value="draw"
                      control={<RadioButton />}
                      label={intl.formatMessage({
                        id: I18nKey.IUU_SETTINGS_GEOFENCE_DRAW,
                      })}
                      disabled={
                        isGeofenceEdit && geofenceGeneral.data !== 'draw'
                      }
                      sx={GeofenceCRUDStyles.radioButton}
                    />
                    <FormControlLabel
                      value="upload"
                      control={<RadioButton />}
                      label={intl.formatMessage({
                        id: I18nKey.IUU_SETTINGS_GEOFENCE_UPLOAD,
                      })}
                      disabled={
                        isGeofenceEdit && geofenceGeneral.data !== 'upload'
                      }
                      sx={GeofenceCRUDStyles.radioButton}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Grid>
          {geofenceGeneral.data === 'draw' && (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  '& .MuiTypography-root': { color: 'white' },
                }}
              >
                <Box sx={{ display: 'flex', textAlign: 'left' }}>
                  <Button
                    sx={{
                      display: 'flex',
                      color: 'white',
                      filter:
                        drawModeValue === 'polygon'
                          ? GeofenceCRUDStyles.drawButton.active
                          : 'none',
                    }}
                    onClick={(event) => {
                      setDrawModeValue('polygon');
                      onClickDraw('polygon');
                    }}
                  >
                    <HighlightAltIcon
                      style={{
                        marginRight: '10px',
                        color: 'gray',
                        filter:
                          drawModeValue === 'polygon'
                            ? GeofenceCRUDStyles.drawButton.active
                            : 'none',
                      }}
                    />
                    {intl.formatMessage({
                      id: I18nKey.IUU_SETTINGS_GEOFENCE_DRAW_POLYGON,
                    })}
                  </Button>
                  <Button
                    sx={{
                      display: 'flex',
                      color: 'white',
                      filter:
                        drawModeValue === 'circle'
                          ? GeofenceCRUDStyles.drawButton.active
                          : 'none',
                    }}
                    onClick={(event) => {
                      setDrawModeValue('circle');
                      onClickDraw('circle');
                    }}
                  >
                    <AllOutIcon
                      style={{
                        marginRight: '10px',
                        color: 'gray',
                        filter:
                          drawModeValue === 'circle'
                            ? GeofenceCRUDStyles.drawButton.active
                            : 'none',
                      }}
                    />
                    {intl.formatMessage({
                      id: I18nKey.IUU_SETTINGS_GEOFENCE_DRAW_CIRCLE,
                    })}
                  </Button>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <IconButton
                    disabled={disableDrawingSave}
                    onClick={onClickSaveDrawing}
                  >
                    <img
                      alt="save"
                      style={{
                        filter: drawMode
                          ? GeofenceCRUDStyles.drawButton.active
                          : GeofenceCRUDStyles.drawButton.inActive,
                      }}
                      src={
                        require('../../../../../assets/icons/save.svg').default
                      }
                    />
                    <Typography sx={{ ml: 1 }}>
                      {intl.formatMessage({
                        id: I18nKey.IUU_SETTINGS_GEOFENCE_SAVE,
                      })}
                    </Typography>
                  </IconButton>
                  <IconButton onClick={onClickDeleteDrawing}>
                    <img
                      alt="delete"
                      style={{
                        filter: GeofenceCRUDStyles.drawButton.active,
                      }}
                      src={
                        require('../../../../../assets/icons/delete.svg')
                          .default
                      }
                    />
                    <Typography sx={{ ml: 1 }}>
                      {intl.formatMessage({
                        id: I18nKey.IUU_SETTINGS_GEOFENCE_DELETE,
                      })}
                    </Typography>
                  </IconButton>
                </Box>
              </Box>
              {drawMode && drawModeValue === 'circle' ? (
                <Typography
                  sx={{ ml: '10px', fontSize: '15px', color: '#d2691e' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      marginTop: '10px',
                    }}
                  >
                    <InfoIcon
                      sx={{
                        fontSize: '18px',
                      }}
                    />
                    {intl.formatMessage({
                      id: I18nKey.IUU_SETTINGS_GEOFENCE_DRAW_INFO_CIRCLE,
                    })}
                  </Box>
                  {intl.formatMessage({
                    id: I18nKey.IUU_SETTINGS_GEOFENCE_DRAW_INFO_CIRCLE_RULE_1,
                  })}
                  <br></br>
                  {intl.formatMessage({
                    id: I18nKey.IUU_SETTINGS_GEOFENCE_DRAW_INFO_CIRCLE_RULE_2,
                  })}
                  <br />
                  {intl.formatMessage({
                    id: I18nKey.IUU_SETTINGS_GEOFENCE_DRAW_INFO_CIRCLE_RULE_3,
                  })}
                  <br></br>
                  {intl.formatMessage({
                    id: I18nKey.IUU_SETTINGS_GEOFENCE_DRAW_INFO_CIRCLE_RULE_4,
                  })}
                  <br></br>
                  {intl.formatMessage({
                    id: I18nKey.IUU_SETTINGS_GEOFENCE_DRAW_INFO_CIRCLE_RULE_5,
                  })}
                  <br></br>{' '}
                </Typography>
              ) : null}
              {drawMode && drawModeValue === 'polygon' ? (
                <Typography
                  sx={{ ml: '10px', fontSize: '15px', color: '#d2691e' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      marginTop: '10px',
                    }}
                  >
                    <InfoIcon
                      sx={{
                        fontSize: '18px',
                      }}
                    />

                    {intl.formatMessage({
                      id: I18nKey.IUU_SETTINGS_GEOFENCE_DRAW_INFO_POLYGON,
                    })}
                  </Box>
                  {intl.formatMessage({
                    id: I18nKey.IUU_SETTINGS_GEOFENCE_DRAW_INFO_POLYGON_RULE_1,
                  })}
                  <br></br>
                  {intl.formatMessage({
                    id: I18nKey.IUU_SETTINGS_GEOFENCE_DRAW_INFO_POLYGON_RULE_2,
                  })}
                  <br />
                  {intl.formatMessage({
                    id: I18nKey.IUU_SETTINGS_GEOFENCE_DRAW_INFO_POLYGON_RULE_3,
                  })}
                  <br></br>
                  {intl.formatMessage({
                    id: I18nKey.IUU_SETTINGS_GEOFENCE_DRAW_INFO_POLYGON_RULE_4,
                  })}
                  <br></br>
                  {intl.formatMessage({
                    id: I18nKey.IUU_SETTINGS_GEOFENCE_DRAW_INFO_POLYGON_RULE_5,
                  })}
                </Typography>
              ) : null}
            </Grid>
          )}
          {geofenceGeneral.data === 'upload' && (
            <>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <label htmlFor="contained-button-file">
                    <Input
                      accept=".zip,.kml"
                      id="contained-button-file"
                      type="file"
                      key={fileKey}
                      onInput={onFileChange}
                    />
                    <Button
                      variant="contained"
                      component="span"
                      sx={{
                        color: 'black',
                        background: 'orange',
                        '&:hover': {
                          background: 'darkOrange',
                        },
                      }}
                    >
                      <CloudUploadIcon />
                    </Button>
                  </label>
                  {(file || geofenceGeneral.uploadedFileName) && (
                    <>
                      <Chip
                        label={file?.name || geofenceGeneral.uploadedFileName}
                        variant="outlined"
                        onDelete={onFileDelete}
                        sx={{ color: 'white', ml: 1 }}
                      />
                      <Button
                        aria-label="upload-file"
                        variant="contained"
                        component="span"
                        sx={{
                          color: 'black',
                          background: 'orange',
                          '&:hover': {
                            background: 'darkOrange',
                          },
                          ml: 1,
                        }}
                        onClick={onFileUpload}
                      >
                        <CheckBoxIcon
                          sx={{ fontSize: '20px', marginRight: '10px' }}
                        />
                        {intl.formatMessage({
                          id: I18nKey.IUU_SETTINGS_GEOFENCE_UPLOAD_FILE,
                        })}
                      </Button>
                    </>
                  )}
                </Box>
              </Grid>

              <Typography
                sx={{ ml: '50px', fontWeight: '500', mb: '10px', mt: '5px' }}
              >
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_UPLOAD_INFO,
                })}
              </Typography>
              <Typography
                sx={{ ml: '50px', fontSize: '15px', color: '#d2691e' }}
              >
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_UPLOAD_KML_RULE,
                })}
                <br></br>
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_UPLOAD_KML_RULE_1,
                })}
                <br></br>
                <br></br>
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_UPLOAD_INFO_RULES,
                })}
                <br></br>{' '}
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_UPLOAD_INFO_RULE_1,
                })}
                <br></br>
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_UPLOAD_INFO_RULE_2,
                })}{' '}
                <br></br>{' '}
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_UPLOAD_INFO_RULE_3,
                })}
              </Typography>
            </>
          )}
        </Grid>
        <Divider sx={{ borderColor: '#333333', my: 2 }} flexItem />
        <Typography sx={{ mb: 1 }}>
          {intl.formatMessage({
            id: I18nKey.IUU_SETTINGS_GEOFENCE_DISPLAY,
          })}
        </Typography>
        <Grid container columnSpacing={6} rowSpacing={2}>
          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                '& .MuiBox-root': {
                  top: '0px',
                },
              }}
            >
              <Typography sx={{ mb: 1 }}>
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_FILL_COLOR,
                })}
              </Typography>
              <Grid container columnSpacing={2}>
                <Grid item xs={8}>
                  <ColorBox
                    key={'fill-color'}
                    showColorPicker={true}
                    onChangeColor={onFillColorChange}
                    color={geofenceGeneral.display.fillColor}
                    boxStyle={GeofenceCRUDStyles.boxStyle}
                  ></ColorBox>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                '& .MuiBox-root': {
                  top: '0px',
                },
              }}
            >
              <Typography sx={{ mb: 1 }}>
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_BORDER_COLOR,
                })}
              </Typography>
              <Grid container columnSpacing={2}>
                <Grid item xs={8}>
                  <ColorBox
                    key={'stroke-color'}
                    showColorPicker={true}
                    onChangeColor={onStrokeColorChange}
                    color={geofenceGeneral.display.strokeColor}
                    boxStyle={GeofenceCRUDStyles.boxStyle}
                  ></ColorBox>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography sx={{ mb: 1 }}>
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_FILL_WIDTH,
                })}
              </Typography>
              <Stepper
                value={geofenceGeneral.display.fillWidth}
                key="fill-width"
                onChange={onFillWidthChange}
              />
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: '#333333', my: 2 }} flexItem />
        <Grid container>
          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography sx={{ mb: 1 }}>
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_ALERT,
                })}
              </Typography>
              <Box sx={{ ml: '1%' }}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="style"
                    name="style-radio-buttons-group"
                    value={alertRadioType}
                    onChange={onAlertRadioChange}
                  >
                    <FormControlLabel
                      value="email"
                      control={<RadioButton />}
                      label={intl.formatMessage({
                        id: I18nKey.IUU_SETTINGS_GEOFENCE_EMAIL,
                      })}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              error
              id="geofence-email-input"
              placeholder={intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_GEOFENCE_EMAIL_PLACEHOLDER,
              })}
              value={geofenceGeneral.email}
              onChange={onEmailIdChange}
              sx={GeofenceCRUDStyles.geofenceTextArea}
              helperText={
                error.email &&
                intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_GEOFENCE_EMAIL_ERROR_MESSAGE,
                })
              }
            />
          </Grid>
        </Grid>
        {loading && <ProgressSpinner showSpinner={loading} />}
      </Box>
    </>
  );
};
