import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import closeIcon from '../../assets/icons/close.svg';
import { I18nKey } from '../../translations/I18nKey';
import { useStyles } from '../../utils/util';
import { PopUpStyles } from '../Map/IUU/PopUp/VesselPopUpStyles';

const Value = styled(Paper)(PopUpStyles.ValueStyle);

const DashboardTrackPopUp: React.FC<any> = ({
  trackData,
  position,
  setShowTracksPopUp,
  isShowPopUp,
}) => {
  const intl = useIntl();
  const classes = useStyles(PopUpStyles, [])();
  const [isVisible, setIsVisible] = useState(isShowPopUp);
  const popupRef = useRef<any>();

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible]);

  const handleClickOutside = (event: any) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsVisible(false);
      onClose();
    }
  };

  const onClose = () => {
    setShowTracksPopUp({
      display: false,
      trackData: {},
      position: {},
    });
  };

  return (
    <Box
      id="iuu-vessel-track-popup"
      sx={{
        position: 'fixed',
        backgroundColor: 'black',
        borderRadius: 3,
        width: 'auto',
        top: position.top,
        left: position.left,
      }}
      style={{ display: isVisible ? 'inline-block' : 'none' }}
      ref={popupRef}
    >
      <Box
        sx={{
          backgroundColor: '#3D3D3D',
          display: 'flex',
          flexDirection: 'column',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          padding: 1,
          color: 'white',
        }}
      >
        <Typography sx={{ fontSize: '14px' }}>{trackData.name} </Typography>
        <img
          src={closeIcon}
          onClick={onClose}
          className={classes.closeIcon}
          alt="x"
        />
      </Box>
      <Box sx={{ p: 1, width: '234px' }}>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box sx={{ width: '100px' }}>
            <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
              {trackData.source === 'VMS'
                ? intl.formatMessage({
                    id: I18nKey.IUU_POPUP_VESSEL_ID,
                  })
                : intl.formatMessage({
                    id: I18nKey.IUU_POPUP_MMSI,
                  })}
            </Typography>
          </Box>
          <Typography sx={{ color: 'white', fontSize: '14px' }}>
            {trackData.vesselId}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: '100px' }}>
            <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
              {intl.formatMessage({
                id: I18nKey.IUU_POPUP_POSITION,
              })}
            </Typography>
          </Box>
          <Box>
            <Value>
              {trackData.position.lat} <br></br>
              {trackData.position.lon}
            </Value>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Box sx={{ width: '100px' }}>
            <Typography sx={{ color: '#B6B2B2', fontSize: '14px' }}>
              {intl.formatMessage({
                id: I18nKey.IUUDASHBOARD_TRACKS_POPUP_DATE,
              })}
            </Typography>
          </Box>
          <Typography sx={{ color: 'white', fontSize: '14px' }}>
            {trackData?.date?.split('T')[0]} {trackData?.date?.split('T')[1]}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardTrackPopUp;
