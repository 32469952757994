import { useState, useEffect, useRef } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { isEmpty, isNil } from 'lodash';
import axios from 'axios';
import { Box } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import moment from 'moment';
import UserClient from '../../api/userAPIs';
import { DataFilesList, ObjectKeys } from '../types';
import { getUserAttributes } from '../../utils/auth';
import { RegionContext } from './../Contexts/RegionContext';
import { UserSettingsContext } from './../Contexts/UserSettingsContext';
import { updateUserRegion } from '../../utils/util';
import { MAPBOX_TILES } from './config';
import { addGraticule } from './Products/MapLayers/GraticuleService';
import {
  getSelectedProductsFromStorage,
  removeLayerSource,
} from './HelperService';
import {
  drawBathymetryLayer,
  drawEezLayer,
  toggleBathymetrylayers,
  toggleEezLayer,
} from './Products/MapLayers/BoundaryLayerService';
import ProductClient from '../../api/productAPIs';
import './Map.scss';

export interface Map {
  map: mapboxgl.Map | null;
  selectedDate: Date | undefined;
  setUserSelectedDate: any;
  dataFilesListByUserRegion: any;
  date: string;
  setSelectedDate: any;
  addMapLayerId: any;
  removeMapLayerId: any;
  layerOrder: any;
}

export interface MapLayers extends ObjectKeys {
  bathymetry: string;
  eez: string;
  recommendation: string[];
  raster: string;
  contour: string[];
  vector: string[];
  mastercast: string;
  tripPoints: string;
  tripLines: string;
  geofence: string[];
  RF: string[];
  darkTargets: string[];
  vessels: string[];
  weather: string[];
}

export const MapContext = createContext<Map>({
  map: null,
  selectedDate: new Date(),
  setUserSelectedDate: () => {},
  dataFilesListByUserRegion: [],
  date: '',
  setSelectedDate: () => {},
  addMapLayerId: () => {},
  removeMapLayerId: () => {},
  layerOrder: () => {},
});

export const MapProvider: React.FC = (props) => {
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESSTOKEN || '';
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const userSettings = useContextSelector(
    UserSettingsContext,
    (state) => state.userSettings
  );
  const { userName } = getUserAttributes();
  const regions = useContextSelector(RegionContext, (state) => state);
  const [dataFilesList, setDataFilesList] = useState<DataFilesList[]>([]);
  const [selectedDate, setSelectedDate] = useState();
  const [date, setDate] = useState('');
  const [dataFilesListByUserRegion, setDataFilesListByUserRegion] =
    useState<DataFilesList[]>();
  const currentZoom = useRef(0);

  const setUserSelectedDate = (date: string | any) => {
    let userDate =
      isEmpty(date) || isNil(date)
        ? moment(new Date(date)).format('YYYYMMDD')
        : date;

    sessionStorage.setItem('date', userDate);
    setDate(userDate);
    setSelectedDate(date);
  };

  const userRegion = useContextSelector(
    UserSettingsContext,
    (state) => state.userSettings.map?.regionsOfInterest
  );

  const getDataFilesListByUserRegion = () =>
    dataFilesList.filter((data) => data.regions === userRegion);

  const getDataFilesList = async (
    username: string,
    seastarId: string
  ): Promise<any> => {
    try {
      const dataFiles = await UserClient.getDataFilesList(username, seastarId);

      if (!isEmpty(dataFiles)) setDataFilesList(dataFiles);
    } catch (error: any) {
      if (error.response.status === 404) {
        setDataFilesList([]);
        console.error(error.response);
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(dataFilesList)) {
      setDataFilesListByUserRegion(getDataFilesListByUserRegion());
    }
  }, [dataFilesList, userRegion]);

  const setStorageItems = () => {
    if (isNil(getSelectedProductsFromStorage()))
      localStorage.setItem(
        'selectedProducts',
        JSON.stringify({ raster: [], contour: [], vector: [] })
      );

    let mapLayers: MapLayers = {
      bathymetry: '',
      eez: '',
      recommendation: [],
      raster: '',
      contour: [],
      vector: [],
      mastercast: '',
      tripPoints: '',
      tripLines: '',
      geofence: [],
      RF: [],
      darkTargets: [],
      vessels: [],
      weather: [],
    };
    sessionStorage.setItem('mapLayers', JSON.stringify(mapLayers));
  };

  useEffect(() => {
    setStorageItems();

    const userInfo = getUserAttributes();
    getDataFilesList(userInfo.userName, userInfo.seastarId);

    const defaultMap = new mapboxgl.Map({
      container: 'mapContainer',
      style: MAPBOX_TILES,
      center: [-244.5, 4],
      zoom: 4,
      minZoom: 1.2,
      maxZoom: 21,
    });
    defaultMap.resize();
    defaultMap.doubleClickZoom.disable();
    defaultMap.dragRotate.disable();
    defaultMap.touchZoomRotate.disable();
    const scale = new mapboxgl.ScaleControl({
      maxWidth: 100,
      unit: 'nautical',
    });
    defaultMap?.addControl(scale, 'bottom-right');
    setMap(defaultMap);

    const dateFromStorage = sessionStorage.getItem('date');
    const momentDate = moment(dateFromStorage || new Date()).toDate();

    setUserSelectedDate(momentDate);
  }, []);

  useEffect(() => {
    // TODO: this is making the app slow. Need to revisit
    if (!isEmpty(regions) && !isEmpty(userRegion) && !isEmpty(map)) {
      updateUserRegion(map, regions, userRegion);
    }
  }, [regions, userRegion, map]);

  useEffect(() => {
    if (map && userSettings.map?.regionsOfInterest) {
      getBoundariesDataURL(
        userName,
        userSettings.map?.regionsOfInterest,
        'BTH'
      );
      getBoundariesDataURL(
        userName,
        userSettings.map?.regionsOfInterest,
        'EEZ'
      );
    }
  }, [map, userSettings.map?.regionsOfInterest]);

  useEffect(() => {
    map && updateBaseLayer();
  }, [map, userSettings.map?.defaultBaseMapCode]);

  useEffect(() => {
    if (map) {
      map.on('load', () => {
        drawGraticule();
        map?.on('wheel', () => {
          drawGraticule();
        });
      });
      if (map?.getLayer('base')) {
        drawGraticule();
        map?.on('wheel', () => {
          drawGraticule();
        });
      }
    }
  }, [map, userSettings.map?.showGraticule]);

  useEffect(() => {
    return () => {
      if (map) {
        map.remove();
      }
    };
  }, []);

  useEffect(() => {
    map &&
      toggleBathymetrylayers(
        map,
        userSettings.map?.showBathymetryContour || false,
        userSettings.map?.showBathymetryLabel || false
      );
  }, [
    map,
    userSettings.map?.showBathymetryContour,
    userSettings.map?.showBathymetryLabel,
  ]);

  useEffect(() => {
    map && toggleEezLayer(map, userSettings.map?.showEez || false);
  }, [map, userSettings.map?.showEez]);

  const drawGraticule = () => {
    if (map) {
      removeLayerSource(map, 'graticule', ['graticule', 'graticuleLabels']);
      if (userSettings.map?.showGraticule) {
        currentZoom.current = 0;
        currentZoom.current = addGraticule(
          map,
          currentZoom.current,
          map?.getZoom() || 0
        );
      } else {
        map.off('wheel', drawGraticule);
      }
    }
  };

  const getBoundariesDataURL = async (
    userName: string,
    region: string,
    product: string
  ) => {
    map && removeLayerSource(map, 'bathymetry', ['labels', 'bathymetry']);
    try {
      const url = await ProductClient.generateMapTile(
        userName,
        '20201010',
        region,
        product,
        'contour',
        -1,
        true
      );

      getData(url, region, product);
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async (url: string, region: string, product: string) => {
    try {
      const { data } = await axios.get<any>(url);
      if (data.hasOwnProperty('features') && data.features.length > 0) {
        if (product === 'BTH') {
          addMapLayerId('bathymetry', 'bathymetry');
          map &&
            drawBathymetryLayer(
              map,
              data,
              userSettings.map?.showBathymetryContour || false,
              userSettings.map?.showBathymetryLabel || false,
              layerOrder
            );
        }
        if (product === 'EEZ') {
          addMapLayerId('eez', 'eez');
          map &&
            drawEezLayer(
              map,
              data,
              userSettings.map?.showEez || false,
              layerOrder
            );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateBaseLayer = () => {
    if (map?.getLayer('base')) {
      map?.removeLayer('base');
      map?.addLayer(
        {
          id: 'base',
          type: 'raster',
          source: userSettings.map?.defaultBaseMapCode,
          minzoom: 0,
          maxzoom: 21,
        },
        layerOrder('base')
      );
    }
  };

  const addMapLayerId = (key: string, layerId: string) => {
    let layers: any = sessionStorage.getItem('mapLayers');
    if (layers) {
      layers = JSON.parse(layers);
      if (typeof layers[key] === 'string') {
        layers[key] = layerId;
      } else {
        if (!layers[key].includes(layerId)) layers[key].push(layerId);
      }
      sessionStorage.setItem('mapLayers', JSON.stringify(layers));
    }
  };

  const removeMapLayerId = (key: string, layerId: string) => {
    let layers: any = sessionStorage.getItem('mapLayers');
    if (layers) {
      layers = JSON.parse(layers);
      if (typeof layers[key] === 'string') {
        layers[key] = '';
      } else if (layers[key] && layers[key].length)
        layers[key] = layers[key].filter((l: string) => l !== layerId);
      sessionStorage.setItem('mapLayers', JSON.stringify(layers));
    }
  };

  const layerOrder = (layerName: string) => {
    let mapLayers: any = sessionStorage.getItem('mapLayers');
    if (mapLayers) {
      mapLayers = JSON.parse(mapLayers);
      switch (layerName) {
        case 'base': {
          return map?.getLayer(mapLayers.raster)
            ? mapLayers.raster
            : map?.getLayer(mapLayers.mastercast)
            ? mapLayers.mastercast
            : map?.getLayer(mapLayers.bathymetry)
            ? mapLayers.bathymetry
            : map?.getLayer(mapLayers.eez)
            ? mapLayers.eez
            : mapLayers.contour.length
            ? mapLayers.contour[0]
            : mapLayers.vector.length
            ? mapLayers.vector[0]
            : mapLayers.weather.length
            ? mapLayers.weather[0]
            : mapLayers.recommendation.length
            ? mapLayers.recommendation[0]
            : mapLayers.geofence.length
            ? mapLayers.geofence[0]
            : mapLayers.darkTargets.length
            ? mapLayers.darkTargets[0]
            : mapLayers.RF.length
            ? mapLayers.RF[0]
            : mapLayers.vessels.length
            ? mapLayers.vessels[0]
            : map?.getLayer(mapLayers.tripLines)
            ? mapLayers.tripLines
            : map?.getLayer(mapLayers.tripPoints)
            ? mapLayers.tripPoints
            : map?.getLayer('graticule')
            ? 'graticule'
            : '';
        }
        case 'raster': {
          return map?.getLayer(mapLayers.mastercast)
            ? mapLayers.mastercast
            : map?.getLayer(mapLayers.bathymetry)
            ? mapLayers.bathymetry
            : map?.getLayer(mapLayers.eez)
            ? mapLayers.eez
            : mapLayers.contour.length
            ? mapLayers.contour[0]
            : mapLayers.vector.length
            ? mapLayers.vector[0]
            : mapLayers.weather.length
            ? mapLayers.weather[0]
            : mapLayers.recommendation.length
            ? mapLayers.recommendation[0]
            : mapLayers.geofence.length
            ? mapLayers.geofence[0]
            : mapLayers.darkTargets.length
            ? mapLayers.darkTargets[0]
            : mapLayers.RF.length
            ? mapLayers.RF[0]
            : mapLayers.vessels.length
            ? mapLayers.vessels[0]
            : map?.getLayer(mapLayers.tripLines)
            ? mapLayers.tripLines
            : map?.getLayer(mapLayers.tripPoints)
            ? mapLayers.tripPoints
            : map?.getLayer('graticule')
            ? 'graticule'
            : '';
        }
        case 'mastercast': {
          return map?.getLayer(mapLayers.bathymetry)
            ? mapLayers.bathymetry
            : map?.getLayer(mapLayers.eez)
            ? mapLayers.eez
            : mapLayers.contour.length
            ? mapLayers.contour[0]
            : mapLayers.vector.length
            ? mapLayers.vector[0]
            : mapLayers.weather.length
            ? mapLayers.weather[0]
            : mapLayers.recommendation.length
            ? mapLayers.recommendation[0]
            : mapLayers.geofence.length
            ? mapLayers.geofence[0]
            : mapLayers.darkTargets.length
            ? mapLayers.darkTargets[0]
            : mapLayers.RF.length
            ? mapLayers.RF[0]
            : mapLayers.vessels.length
            ? mapLayers.vessels[0]
            : map?.getLayer(mapLayers.tripLines)
            ? mapLayers.tripLines
            : map?.getLayer(mapLayers.tripPoints)
            ? mapLayers.tripPoints
            : map?.getLayer('graticule')
            ? 'graticule'
            : '';
        }
        case 'bathymetry': {
          return map?.getLayer(mapLayers.eez)
            ? mapLayers.eez
            : mapLayers.contour.length
            ? mapLayers.contour[0]
            : mapLayers.vector.length
            ? mapLayers.vector[0]
            : mapLayers.weather.length
            ? mapLayers.weather[0]
            : mapLayers.recommendation.length
            ? mapLayers.recommendation[0]
            : mapLayers.geofence.length
            ? mapLayers.geofence[0]
            : mapLayers.darkTargets.length
            ? mapLayers.darkTargets[0]
            : mapLayers.RF.length
            ? mapLayers.RF[0]
            : mapLayers.vessels.length
            ? mapLayers.vessels[0]
            : map?.getLayer(mapLayers.tripLines)
            ? mapLayers.tripLines
            : map?.getLayer(mapLayers.tripPoints)
            ? mapLayers.tripPoints
            : map?.getLayer('graticule')
            ? 'graticule'
            : '';
        }
        case 'eez': {
          return mapLayers.contour.length
            ? mapLayers.contour[0]
            : mapLayers.vector.length
            ? mapLayers.vector[0]
            : mapLayers.weather.length
            ? mapLayers.weather[0]
            : mapLayers.recommendation.length
            ? mapLayers.recommendation[0]
            : mapLayers.geofence.length
            ? mapLayers.geofence[0]
            : mapLayers.darkTargets.length
            ? mapLayers.darkTargets[0]
            : mapLayers.RF.length
            ? mapLayers.RF[0]
            : mapLayers.vessels.length
            ? mapLayers.vessels[0]
            : map?.getLayer(mapLayers.tripLines)
            ? mapLayers.tripLines
            : map?.getLayer(mapLayers.tripPoints)
            ? mapLayers.tripPoints
            : map?.getLayer('graticule')
            ? 'graticule'
            : '';
        }
        case 'contour': {
          return mapLayers.vector.length
            ? mapLayers.vector[0]
            : mapLayers.weather.length
            ? mapLayers.weather[0]
            : mapLayers.recommendation.length
            ? mapLayers.recommendation[0]
            : mapLayers.geofence.length
            ? mapLayers.geofence[0]
            : mapLayers.darkTargets.length
            ? mapLayers.darkTargets[0]
            : mapLayers.RF.length
            ? mapLayers.RF[0]
            : mapLayers.vessels.length
            ? mapLayers.vessels[0]
            : map?.getLayer(mapLayers.tripLines)
            ? mapLayers.tripLines
            : map?.getLayer(mapLayers.tripPoints)
            ? mapLayers.tripPoints
            : map?.getLayer('graticule')
            ? 'graticule'
            : '';
        }
        case 'vector': {
          return mapLayers.weather.length
            ? mapLayers.weather[0]
            : mapLayers.recommendation.length
            ? mapLayers.recommendation[0]
            : mapLayers.geofence.length
            ? mapLayers.geofence[0]
            : mapLayers.darkTargets.length
            ? mapLayers.darkTargets[0]
            : mapLayers.RF.length
            ? mapLayers.RF[0]
            : mapLayers.vessels.length
            ? mapLayers.vessels[0]
            : map?.getLayer(mapLayers.tripLines)
            ? mapLayers.tripLines
            : map?.getLayer(mapLayers.tripPoints)
            ? mapLayers.tripPoints
            : map?.getLayer('graticule')
            ? 'graticule'
            : '';
        }
        case 'weather': {
          return mapLayers.recommendation.length
            ? mapLayers.recommendation[0]
            : mapLayers.geofence.length
            ? mapLayers.geofence[0]
            : mapLayers.darkTargets.length
            ? mapLayers.darkTargets[0]
            : mapLayers.RF.length
            ? mapLayers.RF[0]
            : mapLayers.vessels.length
            ? mapLayers.vessels[0]
            : map?.getLayer(mapLayers.tripLines)
            ? mapLayers.tripLines
            : map?.getLayer(mapLayers.tripPoints)
            ? mapLayers.tripPoints
            : map?.getLayer('graticule')
            ? 'graticule'
            : '';
        }
        case 'recommendation': {
          return mapLayers.geofence.length
            ? mapLayers.geofence[0]
            : mapLayers.darkTargets.length
            ? mapLayers.darkTargets[0]
            : mapLayers.RF.length
            ? mapLayers.RF[0]
            : mapLayers.vessels.length
            ? mapLayers.vessels[0]
            : map?.getLayer(mapLayers.tripLines)
            ? mapLayers.tripLines
            : map?.getLayer(mapLayers.tripPoints)
            ? mapLayers.tripPoints
            : map?.getLayer('graticule')
            ? 'graticule'
            : '';
        }
        case 'geofence': {
          return mapLayers.darkTargets.length
            ? mapLayers.darkTargets[0]
            : mapLayers.RF.length
            ? mapLayers.RF[0]
            : mapLayers.vessels.length
            ? mapLayers.vessels[0]
            : map?.getLayer(mapLayers.tripLines)
            ? mapLayers.tripLines
            : map?.getLayer(mapLayers.tripPoints)
            ? mapLayers.tripPoints
            : map?.getLayer('graticule')
            ? 'graticule'
            : '';
        }
        case 'darkTargets': {
          return mapLayers.RF.length
            ? mapLayers.RF[0]
            : mapLayers.vessels.length
            ? mapLayers.vessels[0]
            : map?.getLayer(mapLayers.tripLines)
            ? mapLayers.tripLines
            : map?.getLayer(mapLayers.tripPoints)
            ? mapLayers.tripPoints
            : map?.getLayer('graticule')
            ? 'graticule'
            : '';
        }
        case 'RF': {
          return mapLayers.vessels.length
            ? mapLayers.vessels[0]
            : map?.getLayer(mapLayers.tripLines)
            ? mapLayers.tripLines
            : map?.getLayer(mapLayers.tripPoints)
            ? mapLayers.tripPoints
            : map?.getLayer('graticule')
            ? 'graticule'
            : '';
        }
        case 'vessels': {
          return map?.getLayer(mapLayers.tripLines)
            ? mapLayers.tripLines
            : map?.getLayer(mapLayers.tripPoints)
            ? mapLayers.tripPoints
            : map?.getLayer('graticule')
            ? 'graticule'
            : '';
        }
        case 'measure-lines': {
          return map?.getLayer(mapLayers.tripPoints)
            ? mapLayers.tripPoints
            : map?.getLayer('graticule')
            ? 'graticule'
            : '';
        }
        case 'measure-points': {
          return map?.getLayer('graticule') ? 'graticule' : '';
        }
        default: {
          return '';
        }
      }
    }
  };

  return (
    <MapContext.Provider
      value={{
        map,
        selectedDate,
        setUserSelectedDate,
        date,
        setSelectedDate,
        addMapLayerId,
        removeMapLayerId,
        layerOrder,
        dataFilesListByUserRegion,
      }}
    >
      <Box id="mapContainer" className="mapboxgl-canvas"></Box>
      {props.children}
    </MapContext.Provider>
  );
};
