import { Box, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import _, { floor, isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../../../../translations/I18nKey';
import AutoComplete from '../../../../../components/AutoComplete/AutoComplete';
import { Stepper } from '../../../../../components/Stepper/Stepper';
import { AISStyles } from '../AISnVMS/AISnVMSStyles';
import { IUU_SETTINGS_VMS_COLUMNS } from '../../config';

const precision = 1;

export const GeofenceVMS: React.FC<any> = ({
  data,
  displaySectionsConfig,
  geofenceWatchlist,
  setGeofenceWatchlist,
  type,
}) => {
  const intl = useIntl();
  const [filteredData, setFilteredData] = useState<any>(data);
  const [localFleetName, setLocalFleetName] = useState<any>([]);
  const [localVesselName, setLocalVesselName] = useState<any>([]);

  useEffect(() => {
    setLocalFleetName(geofenceWatchlist[type].selectedFleetName);
    setLocalVesselName(geofenceWatchlist[type].selectedVesselName);
  }, []);

  const onFleetNameChange = (e: any) => {
    let filteredVessels = _.filter(data, (vessel: any) => {
      return _.map(e, 'fleetName').includes(vessel.fleetName);
    });
    // To show all vessels when nothing is selected in VesselType
    if (isEmpty(filteredVessels)) filteredVessels = data;
    setFilteredData(filteredVessels);
    setLocalFleetName(e);
    const updatedVesselNames = _.filter(localVesselName, (name: any) => {
      return _.map(e, 'fleetName').includes(name.fleetName);
    });
    setLocalVesselName(updatedVesselNames);

    setGeofenceWatchlist({
      ...geofenceWatchlist,
      [type]: {
        ...geofenceWatchlist[type],
        selectedFleetName: e,
      },
    });
  };

  const onVesselNameChange = (e: any) => {
    setLocalVesselName(e);
    setGeofenceWatchlist({
      ...geofenceWatchlist,
      [type]: {
        ...geofenceWatchlist[type],
        selectedVesselName: e,
      },
    });
  };

  const onFromSpeedChange = (event: any) => {
    setGeofenceWatchlist({
      ...geofenceWatchlist,
      [type]: {
        ...geofenceWatchlist[type],
        speed: {
          ...geofenceWatchlist[type].speed,
          fromSpeed: floor(event.target.value, precision),
        },
      },
    });
  };

  const onToSpeedChange = (event: any) => {
    setGeofenceWatchlist({
      ...geofenceWatchlist,
      [type]: {
        ...geofenceWatchlist[type],
        speed: {
          ...geofenceWatchlist[type].speed,
          toSpeed: floor(event.target.value, precision),
        },
      },
    });
  };

  const getUniqueData = (data: any, type: string) => {
    return _.uniqBy(data, type);
  };

  return (
    <Box
      sx={{
        mr: 1,
        '& .chipWrapper': {
          marginLeft: 0,
        },
        '& .MuiAutocomplete-root': {
          marginLeft: '-10px',
        },
      }}
    >
      <>
        <Divider
          sx={{ borderColor: '#333333', mt: 2, mb: -2 }}
          flexItem
        ></Divider>
        <Box>
          <AutoComplete
            key={IUU_SETTINGS_VMS_COLUMNS.fleetNameColumnConfig[0].name}
            data={getUniqueData(
              data,
              IUU_SETTINGS_VMS_COLUMNS.fleetNameColumnConfig[0].name
            )}
            style={AISStyles.autocompleteStyle}
            placeholder={intl.formatMessage({
              id: I18nKey.IUU_SETTINGS_FLEET_NAME_PLACEHOLDER,
            })}
            columnConfig={IUU_SETTINGS_VMS_COLUMNS.fleetNameColumnConfig}
            onSelectionChange={onFleetNameChange}
            showSelectAllToggle={false}
            selectionData={localFleetName}
          />
        </Box>
      </>
      <>
        <Divider
          sx={{ borderColor: '#333333', mt: 2, mb: -2 }}
          flexItem
        ></Divider>
        <Box>
          <AutoComplete
            key={IUU_SETTINGS_VMS_COLUMNS.vesselNameColumnConfig[0].name}
            data={getUniqueData(
              filteredData,
              IUU_SETTINGS_VMS_COLUMNS.vesselNameColumnConfig[0].name
            )}
            style={AISStyles.autocompleteStyle}
            placeholder={intl.formatMessage({
              id: I18nKey.IUU_SETTINGS_VESSEL_NAME_PLACEHOLDER,
            })}
            columnConfig={IUU_SETTINGS_VMS_COLUMNS.vesselNameColumnConfig}
            onSelectionChange={onVesselNameChange}
            showSelectAllToggle={false}
            selectionData={localVesselName}
          />
        </Box>
      </>
      {displaySectionsConfig.showSpeed && (
        <>
          <Divider sx={{ borderColor: '#333333', mt: 2 }} flexItem></Divider>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              color: '#FFF',
              mt: 2,
            }}
          >
            <Typography sx={{ mb: 1 }}>
              {intl.formatMessage({
                id: I18nKey.IUU_SETTINGS_SPEED,
              })}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ mr: 2 }}>
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_FROM_TO,
                })}
              </Typography>
              <Box sx={{ width: '12%', mr: 1 }}>
                <Stepper
                  value={geofenceWatchlist[type].speed.fromSpeed}
                  key="from"
                  onChange={onFromSpeedChange}
                />
              </Box>
              <Typography>-</Typography>
              <Box
                sx={{
                  width: '12%',
                  ml: 1,
                  mr: 2,
                  backgroundColor: '#242424',
                }}
              >
                <Stepper
                  value={geofenceWatchlist[type].speed.toSpeed}
                  key="from"
                  onChange={onToSpeedChange}
                />
              </Box>
              <Typography>
                {intl.formatMessage({
                  id: I18nKey.IUU_SETTINGS_KNOTS,
                })}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
