import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  Tooltip,
} from '@mui/material';
import {
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  MuiEvent,
} from '@mui/x-data-grid';
import _ from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import * as IUUClient from '../../api/iuuAPIs';
import { DataTable } from '../../components/DataTable/DataTable';
import RadioButton from '../../components/RadioButton/RadioButton';
import Snackbar from '../../components/Snackbar/Snackbar';
import { StackBarChart } from '../../components/StackBarChart/StackBarChart';
import { Toggle } from '../../components/Toggle/Toggle';
import { I18nKey } from '../../translations/I18nKey';
import { dateToString } from '../../utils/util';
import {
  vesselTypeBarChartConfig,
  IUUDashboardDataGridConfig,
  tableDateFormat,
} from './DashboardConfig';
import './DashboardMap.scss';
import { IUUDashboardService } from './IUUDashboardService';
import { useIUUDashboardStyles } from './IUUDashboardStyles';
import { getPositionForPopUp } from '../Map/HelperService';
import { DashboardVesselPopUp } from './DashboardVesselPopUp';
import DashboardTrackPopUp from './DashboardTrackPopUp';

const Widgets: React.FC<any> = ({
  userName,
  chartData,
  sunburstChartData,
  geofenceData,
  filteredBreachData,
  filteredAISData,
  loadingVesselDetails,
  onSelectedVessel,
  setLoading,
  onVesselViewChange,
  selectedVesselView,
  loadPopup,
  loadTracksPopup,
  map,
}) => {
  const intl = useIntl();
  const [vesselTypeBarChartData] = chartData;
  const [breachDataGridRows, setBreachDataGridRows] = useState<any>([]);
  const [allDataGridRows, setAllDataGridRows] = useState<any>([]);
  const avoidVesselAlertList = useRef<any>({});
  const [toggleAllAlerts, setToggleAllAlerts] = useState<any>(false);
  const IUUDashboardClasses = useIUUDashboardStyles();
  const [alert, setAlert] = useState({
    type: '',
    display: false,
    message: '',
  });
  const [showPopUp, setShowPopUp] = useState<Record<string, any>>({
    display: false,
    vessel: {},
    layer: '',
  });
  const [showTracksPopUp, setShowTracksPopUp] = useState<Record<string, any>>({
    display: false,
    trackData: {},
    position: {},
  });

  useEffect(() => {
    if (loadPopup) {
      loadPopUp('AIS');
      loadPopUp('breachLayer');
    }
  }, [loadPopup]);

  useEffect(() => {
    loadTracksPopup && loadTrackPopUp('vesselPathDots');
  }, [loadTracksPopup]);

  useEffect(() => {
    const data = filteredAISData.map((element: any, index: number) => {
      return {
        ...element,
        date: dateToString(element.date, tableDateFormat),
        id: index,
      };
    });
    setAllDataGridRows(data);
  }, [filteredAISData]);

  useEffect(() => {
    const tableData = IUUDashboardService.getTableData(
      filteredBreachData,
      geofenceData
    );
    const data = tableData.map((element: any, index: number) => {
      return { ...element, id: index };
    });
    setBreachDataGridRows(data);
    if (data.every((d: any) => d.isAlertEnabled)) setToggleAllAlerts(false);
    else setToggleAllAlerts(true);
  }, [filteredBreachData]);

  useEffect(() => {
    const data = filteredAISData.map((element: any, index: number) => {
      return {
        ...element,
        date: dateToString(element.date, tableDateFormat),
        id: index,
      };
    });
    setAllDataGridRows(data);
  }, [filteredAISData]);

  useEffect(() => {
    const tableData = IUUDashboardService.getTableData(
      filteredBreachData,
      geofenceData
    );
    const data = tableData.map((element: any, index: number) => {
      return { ...element, id: index };
    });
    setBreachDataGridRows(data);
    if (data.every((d: any) => d.isAlertEnabled)) setToggleAllAlerts(false);
    else setToggleAllAlerts(true);
  }, [filteredBreachData]);

  const clearAlert = () =>
    setTimeout(() => {
      setAlert({
        type: '',
        display: false,
        message: '',
      });
    }, 5000);

  const setAlertMessage = (message: string, type: string) => {
    setAlert({
      type,
      display: true,
      message,
    });
    clearAlert();
  };

  useEffect(() => {
    let avoidList: any = {};
    geofenceData.forEach((g: any) => {
      avoidList[g.id] = [];
      if (!_.isEmpty(g.aisWatchFilter)) {
        avoidList[g.id].push({ AIS: g.aisWatchFilter?.avoid || '' });
      }
      if (!_.isEmpty(g.vmsWatchFilter)) {
        avoidList[g.id].push({ VMS: g.vmsWatchFilter?.avoid || '' });
      }
    });
    avoidVesselAlertList.current = avoidList;
  }, [geofenceData]);

  function onChangeAvoid(row: any) {
    row.isAlertEnabled = !row.isAlertEnabled;
    let avoidList = avoidVesselAlertList.current[row.geofenceId]
      .find((k: any) => Object.keys(k).includes(row.vesselSource))
      [row.vesselSource].split(',')
      .filter(Boolean);

    if (row.isAlertEnabled) {
      //remove from avoid list if already exists
      if (avoidList?.includes(row.vesselId)) {
        avoidList = _.filter(avoidList, (v) => v !== row.vesselId);
      }
    } else {
      //add to avoid list
      if (!avoidList?.includes(row.vesselId)) {
        avoidList?.push(row.vesselId);
      }
    }

    let sourceOb: any = {};
    sourceOb[row.vesselSource] = avoidList?.join(',');
    let avoidListIndex = avoidVesselAlertList.current[row.geofenceId].findIndex(
      (k: any) => Object.keys(k).includes(row.vesselSource)
    );
    avoidVesselAlertList.current[row.geofenceId].splice(
      avoidListIndex,
      1,
      sourceOb
    );
    avoidVesselAlertList.current = {
      ...avoidVesselAlertList.current,
    };
  }

  const columns: GridColDef[] = [
    {
      field: 'breachDate',
      width: 100,
      headerName: intl.formatMessage({
        id: I18nKey.IUUDASHBOARD_BREACH_DATE,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      type: 'date',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={String(params.value)}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'isAlertEnabled',
      width: 90,
      headerName: 'Alert',
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      sortable: false,
      // renderHeader: (params: GridColumnHeaderParams) => {
      //   return (
      //     <>
      //       {'Alert'}
      //       <Toggle
      //         checked={toggleAllAlerts}
      //         onChange={(e) => {
      //           console.log(e, params.field);
      //           onClickToggleAllAlerts(!toggleAllAlerts);
      //           setToggleAllAlerts(!toggleAllAlerts);
      //         }}
      //       />
      //     </>
      //   );
      // },
      renderCell: (params: GridCellParams) => {
        return (
          <Toggle
            checked={params.row.isAlertEnabled}
            onChange={(e) => {
              onChangeAvoid(params.row);
            }}
          />
        );
      },
    },
    {
      field: 'geofenceType',
      minWidth: 100,
      flex: 1,
      headerName: intl.formatMessage({
        id: I18nKey.IUUDASHBOARD_GEOFENCE_TYPE,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
    },
    {
      field: 'vesselName',
      width: 180,
      headerName: intl.formatMessage({
        id: I18nKey.IUUDASHBOARD_VESSEL_NAME,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={String(params.value)}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'vesselType',
      width: 100,
      headerName: intl.formatMessage({
        id: I18nKey.IUUDASHBOARD_VESSEL_TYPE,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={String(params.value)}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'flagCountry',
      width: 100,
      headerName: intl.formatMessage({
        id: I18nKey.IUUDASHBOARD_FILTERCOUNTRY,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={String(params.value)}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'geofenceName',
      minWidth: 100,
      flex: 1,
      headerName: intl.formatMessage({
        id: I18nKey.IUUDASHBOARD_GEOFENCE_NAME,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={String(params.value)}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'speed',
      width: 90,
      headerName: intl.formatMessage({
        id: I18nKey.IUUDASHBOARD_SPEED,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
    },
  ];

  const allDataColumns: GridColDef[] = [
    {
      field: 'date',
      width: 180,
      headerName: intl.formatMessage({
        id: I18nKey.IUUDASHBOARD_BREACH_DATE,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      type: 'date',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={String(params.value)}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'vesselName',
      width: 180,
      headerName: intl.formatMessage({
        id: I18nKey.IUUDASHBOARD_VESSEL_NAME,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={String(params.value)}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'vesselId',
      width: 140,
      headerName: 'MMSI',
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={String(params.value)}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'vesselType',
      width: 150,
      headerName: intl.formatMessage({
        id: I18nKey.IUUDASHBOARD_VESSEL_TYPE,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={String(params.value)}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'flagCountry',
      width: 120,
      headerName: intl.formatMessage({
        id: I18nKey.IUUDASHBOARD_FILTERCOUNTRY,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title={String(params.value)}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'sog',
      width: 90,
      headerName: intl.formatMessage({
        id: I18nKey.IUUDASHBOARD_SPEED,
      }),
      headerClassName: 'tableHeaderStyle',
      cellClassName: 'tableCellStyle',
      disableColumnMenu: true,
    },
  ];
  const handleRowClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => {
    if (params.field !== 'isAlertEnabled') {
      const selectedVesselDetails = IUUDashboardService.onVesselClick(
        params.row
      );
      onSelectedVessel(selectedVesselDetails);
    } else {
      event.stopPropagation();
    }
  };

  const onClickVesselTypeChart = (event: any) => {
    const key = 'vessel.vesselType';
    const data: any[] = [];
    filteredBreachData.forEach((vessel: any) => {
      if (
        _.get(vessel, 'vessel.flagCountry') === event.points[0].label &&
        _.get(vessel, key) === event.points[0].data.name
      )
        data.push(vessel);
    });
  };

  const onClickSave = async () => {
    let geofenceDetails = { details: avoidVesselAlertList.current };
    setLoading(true);
    try {
      let saveAvoidList = await IUUClient.saveAvoidAlertVessel(
        userName,
        geofenceDetails
      );
      if (saveAvoidList) {
        setAlertMessage(
          intl.formatMessage({
            id: I18nKey.IUUDASHBOARD_ALERTS_SAVE_MSG,
          }),
          'success'
        );
      }
    } catch (error) {
      setAlertMessage(
        intl.formatMessage({
          id: I18nKey.IUU_SETTINGS_GEOFENCE_SAVE_ERROR_MESSAGE,
        }),
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  // const onClickToggleAllAlerts = (isAlert: boolean) => {
  //   let updatedData = breachDataGridRows.map((d: any) => {
  //     return {
  //       ...d,
  //       ...{ isAlertEnabled: isAlert },
  //     };
  //   });

  //   updatedData.forEach((d: any) => {
  //     onChangeAvoid(d);
  //   });

  //   setbreachDataGridRows(updatedData);
  // };

  const addPopUp = (e: any, source: string) => {
    const point = document
      .getElementById('IUUDashboardMapAISContainer')
      ?.getBoundingClientRect();
    const vesselDetails = e.features[0].properties;
    vesselDetails['position'] = getPositionForPopUp({
      latitude: vesselDetails.lat,
      longitude: vesselDetails.lon,
    });
    vesselDetails['source'] = 'AIS';
    if (point) {
      setShowPopUp({
        display: true,
        vessel: vesselDetails,
        layer: source,
        position: {
          top: (point?.top || 80) + e.point.y,
          left: (point?.left || 10) + e.point.x,
        },
      });
    }
  };

  const loadPopUp = (source: string) => {
    map?.on('click', source, (e: any) => addPopUp(e, source));
  };

  const addTrackPopUp = (e: any) => {
    const point = document
      .getElementById('IUUDashboardMapAISContainer')
      ?.getBoundingClientRect();
    const trackData = e.features[0].properties;
    trackData['position'] = getPositionForPopUp({
      latitude: e.lngLat.lat,
      longitude: e.lngLat.lng,
    });
    trackData['source'] = 'AIS';
    if (point) {
      setShowTracksPopUp({
        display: true,
        trackData: trackData,
        position: {
          top: (point?.top || 80) + e.point.y,
          left: (point?.left || 10) + e.point.x,
        },
      });
    }
  };

  const loadTrackPopUp = (source: string) => {
    map?.on('click', source, (e: any) => addTrackPopUp(e));
  };

  return (
    <>
      {alert.display && (
        <Snackbar
          type={alert.type}
          display={alert.display}
          message={alert.message}
        ></Snackbar>
      )}
      <Box className={IUUDashboardClasses.widgetBoxStyle}>
        <Grid container spacing={4} direction="row">
          <Grid item md={12} lg={6}>
            <Card className={IUUDashboardClasses.chartCardStyle}>
              <CardHeader
                title={intl.formatMessage({
                  id: I18nKey.IUUDASHBOARD_VESSELTYPEBARCHART,
                })}
                className={IUUDashboardClasses.chartHeaderStyle}
              />
              <CardContent
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <StackBarChart
                  data={vesselTypeBarChartData}
                  chartConfig={vesselTypeBarChartConfig}
                  onChartClick={onClickVesselTypeChart}
                  onChartDoubleClick={() => {}}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={12} lg={6}>
            <Card className={IUUDashboardClasses.chartCardStyle}>
              <CardHeader
                title={intl.formatMessage({
                  id: I18nKey.IUUDASHBOARD_TABLE_TITLE,
                })}
                className={IUUDashboardClasses.chartHeaderStyle}
              />
              <CardContent className={IUUDashboardClasses.dataTableStyles}>
                <Box sx={{ ml: '30px', mt: '5px', position: 'absolute' }}>
                  {selectedVesselView && (
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="style"
                        name="style-radio-buttons-group"
                        value={selectedVesselView}
                        onChange={onVesselViewChange}
                      >
                        <FormControlLabel
                          value="all"
                          control={<RadioButton />}
                          label={intl.formatMessage({
                            id: I18nKey.IUUDASHBOARD_ALL_VESSELS,
                          })}
                        />
                        <FormControlLabel
                          value="breached"
                          control={<RadioButton />}
                          label={intl.formatMessage({
                            id: I18nKey.IUUDASHBOARD_ONLY_BREACHED_VESSELS,
                          })}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                </Box>
                {filteredBreachData && selectedVesselView === 'breached' && (
                  <DataTable
                    tableColumns={columns}
                    dataRows={breachDataGridRows}
                    onCellClick={handleRowClick}
                    tableConfig={IUUDashboardDataGridConfig}
                  />
                )}
                {filteredAISData && selectedVesselView === 'all' && (
                  <DataTable
                    tableColumns={allDataColumns}
                    dataRows={allDataGridRows}
                    onCellClick={handleRowClick}
                    tableConfig={IUUDashboardDataGridConfig}
                  />
                )}
                {selectedVesselView === 'breached' && (
                  <Grid container spacing={1} ml={'5px'}>
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        disableElevation
                        classes={{ root: IUUDashboardClasses.actionButton }}
                        onClick={onClickSave}
                      >
                        {intl.formatMessage({
                          id: I18nKey.IUUDASHBOARD_SAVE_ALERT,
                        })}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            md={12}
            lg={12}
            sx={{ p: 1, position: 'relative' }}
            mb={10}
          >
            {loadingVesselDetails && (
              <Container
                className={IUUDashboardClasses.commonFlexCenterStyles}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: '99',
                }}
              >
                <CircularProgress color="inherit" />
              </Container>
            )}
            <Card className={IUUDashboardClasses.mapCardStyle}>
              <CardHeader
                title={intl.formatMessage({
                  id: I18nKey.IUUDASHBOARD_VESSELBREACHMAP,
                })}
                className={IUUDashboardClasses.chartHeaderStyle}
              />
              <Box
                height={'700px'}
                id="IUUDashboardMapAISContainer"
                className="dashboardMapCanvas"
              ></Box>
              {showPopUp.display && (
                <Box sx={{ position: 'fixed' }}>
                  <DashboardVesselPopUp
                    vessel={showPopUp.vessel}
                    layerType={showPopUp.layer}
                    setShowPopUp={setShowPopUp}
                    position={showPopUp.position}
                    breachData={filteredBreachData}
                    isShowPopUp={showPopUp.display}
                  ></DashboardVesselPopUp>
                </Box>
              )}
              {showTracksPopUp.display && (
                <Box sx={{ position: 'fixed' }}>
                  <DashboardTrackPopUp
                    trackData={showTracksPopUp.trackData}
                    position={showTracksPopUp.position}
                    setShowTracksPopUp={setShowTracksPopUp}
                    isShowPopUp={showTracksPopUp.display}
                  ></DashboardTrackPopUp>
                </Box>
              )}
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default Widgets;
