export class FillAreaService {
  public static addFillAreaLayer = (
    map: mapboxgl.Map,
    layerKey: string,
    layerData: any,
    addFilledArea: boolean,
    filledAreaColorData: any,
    addLayerOrdering: boolean,
    layerOrderKey: string,
    addBoundary: boolean,
    boundaryColorData: any,
    addMapLayerId: any,
    layerOrder: any
  ) => {
    addLayerOrdering && addMapLayerId(layerOrderKey, layerKey);
    if (!map?.getSource(layerKey)) {
      map?.addSource(layerKey, {
        type: 'geojson',
        data: layerData,
        promoteId: 'id',
      });
      addBoundary &&
        map?.addLayer(
          {
            id: layerKey + 'outline',
            type: 'line',
            source: layerKey,
            layout: {},
            paint: boundaryColorData,
          },
          layerOrder(layerOrderKey)
        );
      addFilledArea &&
        map.addLayer(
          {
            id: layerKey,
            type: 'fill',
            source: layerKey,
            layout: {},
            paint: filledAreaColorData,
          },
          layerOrder(layerOrderKey)
        );
    }
  };
  public static drawFillProduct = (
    map: mapboxgl.Map,
    code: string,
    type: any,
    tileData: any,
    addMapLayerId: any,
    layerOrder: any,
    setLoading: any
  ) => {
    this.addFillAreaLayer(
      map,
      code + type,
      tileData.data,
      true,
      {
        'fill-color': tileData.color,
      },
      true,
      type,
      false,
      {},
      addMapLayerId,
      layerOrder
    );
    setLoading(false);
  };
}
