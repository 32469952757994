import { useEffect, useRef, useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Button,
} from '@mui/material';
import mapboxgl from 'mapbox-gl';
import { getSelectedRegion } from '../../utils/util';
import { UserSettingsContext } from '../Contexts/UserSettingsContext';
import { RegionContext } from '../Contexts/RegionContext';
import { MAPBOX_TILES } from '../Map/config';
import { useIntl } from 'react-intl';
import { I18nKey } from '../../translations/I18nKey';
import { StackBarChart } from '../../components/StackBarChart/StackBarChart';
import { useFishCatchDashboardStyles } from './FishCatchDashboardStyles';
import { FishCatchDashboardService } from './FishCatchDashboardService';
import Plot from 'react-plotly.js';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  FishCatchMapEnumToI18nKeyMapper,
  HeatMapLayout,
  MAP_DEFAULT_ZOOM,
} from './DashboardConfig';
import { isEmpty, isNil } from 'lodash';
import { getPositionForPopUp } from '../Map/HelperService';
import { FishCatchPopUp } from './FishCatchPopUp';

export enum MapTypes {
  Heat = 'Heat',
  Analysis = 'Analysis',
}

const FishCatchWidgets: React.FC<any> = ({
  fishColorsList,
  fishPerVesselData,
  avgCatchPerSetData,
  fishCatchData,
}) => {
  const intl = useIntl();
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [mapType, setMapType] = useState<MapTypes>(MapTypes.Analysis);
  const [heatMapData, setHeatMapData] = useState<any>([]);
  const [isMapMenuOpen, setIsMapMenuOpen] = useState<boolean>(false);
  const regions = useContextSelector(RegionContext, (state) => state);
  const userRegion = useContextSelector(
    UserSettingsContext,
    (state) => state.userSettings.map?.regionsOfInterest
  );
  const [region, setRegion] = useState<any>({});
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESSTOKEN || '';
  const [showFishCatchPopUp, setShowFishCatchPopUp] = useState<
    Record<string, any>
  >({
    display: false,
    fcData: {},
    position: {},
  });

  const FishCatchDashboardStyles: any = useFishCatchDashboardStyles();

  useEffect(() => {
    const heatData = FishCatchDashboardService.setHeatMapData(
      fishCatchData,
      intl
    );
    setHeatMapData(heatData);
    if (map) {
      renderCatchesOnMap(map, fishCatchData);
    }
  }, [fishCatchData]);

  useEffect(() => {
    if (
      !isEmpty(regions) &&
      !isEmpty(userRegion) &&
      (isEmpty(map) || isNil(map)) &&
      mapType === MapTypes.Analysis
    ) {
      const regionInfo = getSelectedRegion(regions, userRegion);
      setRegion(regionInfo);
      const latLong: any = [
        parseInt(regionInfo.center.split(',')[0]),
        parseInt(regionInfo.center.split(',')[1]),
      ];
      const defaultMap = new mapboxgl.Map({
        container: 'FishCatchDashboardMapContainer',
        style: MAPBOX_TILES,
        center: latLong,
        zoom: regionInfo.initial_zoom,
        minZoom: 0,
        maxZoom: 21,
      });
      defaultMap.resize();
      defaultMap.doubleClickZoom.disable();
      setMap(defaultMap);
    }
  }, [regions, userRegion, mapType]);

  useEffect(() => {
    map && renderCatchesOnMap(map, fishCatchData);
  }, [map]);

  useEffect(() => {
    if (mapType !== MapTypes.Analysis) setMap(null);
  }, [mapType]);

  const renderCatchesOnMap = (map: mapboxgl.Map, catchData: any) => {
    FishCatchDashboardService.removeCatchLayers(map);
    if (catchData.length) {
      let catchPosition: any = { type: 'FeatureCollection', features: [] };
      catchData.forEach((fishCatch: any) => {
        catchPosition.features.push(
          FishCatchDashboardService.getCatchLocationGeojson(fishCatch)
        );
      });
      if (map?.isStyleLoaded()) {
        FishCatchDashboardService.addCatchestoMap(map, catchPosition);
        loadFishCatchPopUp(map, 'fishcatches');
        map.setZoom(MAP_DEFAULT_ZOOM);
      } else {
        map?.on('load', () => {
          FishCatchDashboardService.addCatchestoMap(map, catchPosition);
          loadFishCatchPopUp(map, 'fishcatches');
        });
        map.setZoom(MAP_DEFAULT_ZOOM);
      }
    }
  };

  const loadFishCatchPopUp = (map: mapboxgl.Map, source: string) => {
    map?.on('click', source, (e: any) => {
      const point = document
        .getElementById('FishCatchDashboardMapContainer')
        ?.getBoundingClientRect();
      const fcData = e.features[0].properties;
      fcData['position'] = getPositionForPopUp({
        latitude: fcData.latitude,
        longitude: fcData.longitude,
      });

      setShowFishCatchPopUp({
        display: true,
        fcData: fcData,
        position: {
          top: (point?.top || 80) + e.point.y,
          left: (point?.left || 10) + e.point.x,
        },
      });
    });
  };

  return (
    <Box ml={8} mr={8} pt={2}>
      <Grid container spacing={2} direction="row">
        <Grid item md={12} lg={6}>
          <Card className={FishCatchDashboardStyles.chartsContainer}>
            <CardHeader
              title={intl.formatMessage({
                id: I18nKey.FISH_CATCH_DASHBOARD_FISH_PER_VESSEL_HEADER,
              })}
              className={FishCatchDashboardStyles.chartsHeader}
            />
            <CardContent
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <StackBarChart
                data={fishPerVesselData}
                onChartDoubleClick={() => {}}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={12} lg={6}>
          <Card className={FishCatchDashboardStyles.chartsContainer}>
            <CardHeader
              title={intl.formatMessage({
                id: I18nKey.FISH_CATCH_DASHBOARD_AVG_CATCH_PER_SET_HEADER,
              })}
              className={FishCatchDashboardStyles.chartsHeader}
            />
            <CardContent
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <StackBarChart
                data={avgCatchPerSetData}
                onChartDoubleClick={() => {}}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={12} md={12}>
          <Card className={FishCatchDashboardStyles.mapsContainerExpand}>
            <Box sx={{ position: 'relative' }}>
              <Box className={FishCatchDashboardStyles.mapsSelectMap}>
                {intl.formatMessage({
                  id: I18nKey.FISH_CATCH_DASHBOAD_SELECT_MAP,
                })}
                &nbsp; &nbsp;
                <Button
                  onClick={() => setIsMapMenuOpen(!isMapMenuOpen)}
                  className={FishCatchDashboardStyles.mapsSelectButton}
                >
                  {intl.formatMessage({
                    id: FishCatchMapEnumToI18nKeyMapper[mapType as MapTypes],
                  })}
                  <ExpandMoreIcon />
                </Button>
              </Box>
              <Box
                hidden={!isMapMenuOpen}
                className={FishCatchDashboardStyles.mapsSelectMenu}
              >
                {Object.keys(MapTypes).map((type, index) => (
                  <MenuItem
                    sx={{ zIndex: 3 }}
                    onClick={() => {
                      setMapType(type as MapTypes);
                      setIsMapMenuOpen(false);
                    }}
                    key={index}
                  >
                    {intl.formatMessage({
                      id: FishCatchMapEnumToI18nKeyMapper[type as MapTypes],
                    })}
                  </MenuItem>
                ))}
              </Box>
            </Box>
            {mapType === MapTypes.Heat ? (
              <Plot
                data={heatMapData}
                layout={HeatMapLayout(region).layout}
                config={HeatMapLayout(region).config}
                style={{ width: '100%', height: '90%' }}
              />
            ) : (
              <Box
                height={'700px'}
                id="FishCatchDashboardMapContainer"
                className="dashboardMapCanvas"
              ></Box>
            )}
            {showFishCatchPopUp.display && (
              <Box sx={{ position: 'fixed' }}>
                <FishCatchPopUp
                  fishColorsList={fishColorsList}
                  fcData={showFishCatchPopUp.fcData}
                  setShowFishCatchPopUp={setShowFishCatchPopUp}
                  position={showFishCatchPopUp.position}
                  isShowPopUp={showFishCatchPopUp.display}
                ></FishCatchPopUp>
              </Box>
            )}
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
export default FishCatchWidgets;
