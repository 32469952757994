/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import _, { isEmpty, isNil } from 'lodash';
import { Container } from '@mui/material';
import Snackbar from '../../components/Snackbar/Snackbar';
import mapboxgl from 'mapbox-gl';
import { I18nKey } from '../../translations/I18nKey';
import { getSelectedRegion } from '../../utils/util';
import {
  BreachVesselDetails,
  GeofenceDetails,
  AISData,
  VesselTrackDetails,
} from '../types';
import * as IUUClient from '../../api/iuuAPIs';
import { IUUDashboardService, selectedFilters } from './IUUDashboardService';
import { UserSettingsContext } from '../Contexts/UserSettingsContext';
import { RegionContext } from '../Contexts/RegionContext';
import { MAPBOX_TILES } from '../Map/config';
import { useIUUDashboardStyles } from './IUUDashboardStyles';
import './DashboardMap.scss';
import { Summary } from './IUUDashboardSummary';
import { ProgressSpinner } from '../../components/ProgressSpinner/ProgressSpinner';
import { useContextSelector } from 'use-context-selector';
import { IUUService } from '../Map/IUU/IUUService';
import { MapContext } from '../Map/MapContext';
import Filters from './IUUFilters';
import Widgets from './IUUWidgets';

const AISMain: React.FC<any> = ({ userName, dateRange }) => {
  const intl = useIntl();
  const [geofenceData, setGeofenceData] = useState<GeofenceDetails[]>([]);
  const [breachData, setBreachData] = useState<BreachVesselDetails[]>([]);
  const [filteredBreachData, setFilteredBreachData] = useState<any>([]);
  const [AISData, setAISData] = useState<AISData[]>([]);
  const [filteredAISData, setFilteredAISData] = useState<AISData[]>([]);
  const [renderedChartData, setRenderedChartData] = useState<any>([]);
  const [renderedSunburstChartData, setRenderedSunburstChartData] =
    useState<any>([]);
  const [vesselTypesList, setVesselTypesList] = useState([]);
  const [selectedVesselType, setSelectedVesselType] = useState<any>([]);
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState<any>([]);
  const [selectedVesselView, setSelectedVesselView] = useState<any>();
  const [fenceNameList, setFenceNameList] = useState([]);
  const [selectedFenceNameList, setSelectedFenceNameList] = useState<any>([]);
  const [totalBreaches, setTotalBreaches] = useState<any>(0);
  const [maxBreachesCountry, setMaxBreachesCountry] = useState<any>();
  const [maxBreachesVessel, setMaxBreachVessel] = useState<any>();
  const [selectedVessel, setSelectedVessel] = useState<any>('');
  const [prevSelectedFilters, setPrevSelectedFilters] =
    useState<selectedFilters>();
  const [loadingVesselDetails, setLoadingVesselDetails] =
    useState<boolean>(false);
  const [reRenderMap, setReRenderMap] = useState(false);
  const [vesselTrackData, setVesselTrackData] =
    useState<VesselTrackDetails | undefined>();
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESSTOKEN || '';
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const regions = useContextSelector(RegionContext, (state) => state);
  const [alert, setAlert] = useState({
    type: '',
    display: false,
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [loadingBreachData, setLoadingBreachData] = useState(false);
  const [loadPopup, setLoadPopup] = useState(false);
  const [loadTracksPopup, setLoadTracksPopup] = useState(false);

  const userRegion = useContextSelector(
    UserSettingsContext,
    (state) => state.userSettings.map?.regionsOfInterest
  );
  const addMapLayerId = useContextSelector(
    MapContext,
    (state) => state.addMapLayerId
  );
  const layerOrder = useContextSelector(
    MapContext,
    (state) => state.layerOrder
  );
  const IUUDashboardClasses = useIUUDashboardStyles();

  //Map
  useEffect(() => {
    renderMap();
  }, [reRenderMap]);

  useEffect(() => {
    if (
      !isEmpty(regions) &&
      !isEmpty(userRegion) &&
      (isEmpty(map) || isNil(map))
    ) {
      const region = getSelectedRegion(regions, userRegion);
      const latLong: any = [
        parseInt(region.center.split(',')[0]),
        parseInt(region.center.split(',')[1]),
      ];
      const defaultMap = new mapboxgl.Map({
        container: 'IUUDashboardMapAISContainer',
        style: MAPBOX_TILES,
        center: latLong,
        zoom: region.initial_zoom,
        minZoom: 0,
        maxZoom: 21,
      });
      defaultMap.resize();
      defaultMap.doubleClickZoom.disable();
      setMap(defaultMap);
    }
  }, [regions, userRegion]);

  useEffect(() => {
    if (selectedVessel) {
      fetchVesselTrackData(selectedVessel);
    }
  }, [selectedVessel]);

  useEffect(() => {
    if (vesselTrackData && vesselTrackData[selectedVessel]?.length) {
      addVesselTracks(vesselTrackData);
    }
  }, [vesselTrackData]);

  useEffect(() => {
    if (!isEmpty(dateRange) && map) {
      setLoading(true);
      setLoadingBreachData(true);
      getAISData();
      getBreachData();
      getGeofenceData();
    }
  }, [dateRange, map]);

  useEffect(() => {
    if (selectedFenceNameList.length) {
      IUUDashboardService.removeGeofenceLayer(map, geofenceData);
      geofenceData.forEach((g) => {
        if (_.find(selectedFenceNameList, { id: g.id }))
          IUUDashboardService.getGeofenceGeojson(map, g);
      });
    }
  }, [selectedFenceNameList]);

  useEffect(() => {
    if (map && !isEmpty(AISData) && !isNil(geofenceData)) {
      setSelectedVesselView('all');
      const countryList: any = IUUDashboardService.getDataForFilters(
        AISData,
        'flagCountry',
        'country'
      );
      const vesselTypeList: any = IUUDashboardService.getDataForFilters(
        AISData,
        'vesselType',
        'type'
      );
      const fenceNameList: any =
        IUUDashboardService.setGeofenceData(geofenceData);

      setFenceNameList(fenceNameList);
      setSelectedFenceNameList(fenceNameList);
      setCountryList(countryList);
      setSelectedCountry(countryList);
      setVesselTypesList(vesselTypeList);
      setSelectedVesselType(vesselTypeList);
      const selectedFilters: selectedFilters = {
        country: countryList,
        vesselTypeList: vesselTypeList,
        fenceNameList: fenceNameList,
      };
      setFilterData(selectedFilters, false);
      setPrevSelectedFilters(selectedFilters);
      renderMap();
    }
  }, [geofenceData, AISData, breachData]);

  useEffect(() => {
    if (map) {
      if (selectedVesselView === 'all') {
        const chartData: any = IUUDashboardService.renderCharts(
          filteredAISData,
          false
        );
        setRenderedChartData(chartData);
        map.getSource('AIS')
          ? map.setLayoutProperty('AIS', 'visibility', 'visible')
          : renderMap();
      } else {
        map.getSource('AIS') &&
          map.setLayoutProperty('AIS', 'visibility', 'none');
        const chartData: any = IUUDashboardService.renderCharts(
          filteredBreachData,
          true
        );
        setRenderedChartData(chartData);
        let mapSource =
          IUUDashboardService.createBreachedVesselsGeojson(filteredBreachData);
        const breachVesselObject = {
          layerId: 'breachVesselsOutline',
          source: mapSource,
        };
        IUUDashboardService.addBreachVesselsLayer(
          map,
          breachVesselObject,
          'AIS',
          selectedVesselView
        );
      }
    }
  }, [selectedVesselView]);

  const renderMap = () => {
    IUUDashboardService.removeAllLayers(map);
    if (AISData.length) {
      selectedVesselView === 'all' &&
        IUUService.drawVeselsOnMap(
          map,
          'AIS',
          filteredAISData,
          layerOrder,
          addMapLayerId
        );
      let mapSource =
        IUUDashboardService.createBreachedVesselsGeojson(filteredBreachData);
      const breachVesselObject = {
        layerId: 'breachVesselsOutline',
        source: mapSource,
      };
      IUUDashboardService.addBreachVesselsLayer(
        map,
        breachVesselObject,
        'AIS',
        selectedVesselView
      );
      setLoadPopup(true);
    }
  };

  const clearAlert = () =>
    setTimeout(() => {
      setAlert({
        type: '',
        display: false,
        message: '',
      });
    }, 5000);

  const setAlertMessage = (message: string, type: string) => {
    setAlert({
      type,
      display: true,
      message,
    });
    clearAlert();
  };

  const getGeofenceData = async () => {
    try {
      if (userRegion) {
        const data = await IUUClient.getGeofenceDetails(userName, userRegion);

        if (!isNil(data)) {
          // eslint-disable-next-line array-callback-return
          let filteredGeofences = data.filter((fence) => {
            if (Object.keys(fence.aisWatchFilter).length > 0) return fence;
          });
          setGeofenceData(filteredGeofences);
        }
      }
    } catch (error: any) {
      setGeofenceData([]);
      if (error.response.status === 404)
        setAlertMessage(
          intl.formatMessage({
            id: I18nKey.ERROR_MESSAGE_GEOFENCE,
          }),
          'error'
        );
      else
        setAlertMessage(
          intl.formatMessage({
            id: I18nKey.ERROR_MESSAGE_GET,
          }),
          'error'
        );
    }
  };

  const getBreachData = async () => {
    try {
      let data = await IUUClient.getBreachDetails(
        userName,
        dateRange[0],
        dateRange[1]
      );
      if (!isNil(data)) {
        setBreachData(IUUDashboardService.getUniqueBreachVessels(data));
        if (isEmpty(data)) {
          setAlertMessage(
            intl.formatMessage({
              id: I18nKey.IUUDASHBOARD_NO_BREACH_MSG,
            }),
            'error'
          );
        }
      }
    } catch {
      setAlertMessage(
        intl.formatMessage({
          id: I18nKey.ERROR_MESSAGE_GET,
        }),
        'error'
      );
    } finally {
      setLoadingBreachData(false);
    }
  };

  const getAISData = async () => {
    try {
      if (map) {
        const aisData = await IUUClient.getAllAISData({
          vesselSource: 'AIS',
          userName: userName,
          fromDate: dateRange[0],
          toDate: dateRange[1],
          bottomLeft: IUUService.getBounds(map).bottomLeft,
          topRight: IUUService.getBounds(map).topRight,
          region: userRegion,
        });
        if (aisData.length) {
          setAISData(aisData);
          setLoading(false);
        }
      }
    } catch {
      setLoading(false);
      setAlertMessage(
        intl.formatMessage({
          id: I18nKey.ERROR_MESSAGE_GET,
        }),
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchVesselTrackData = async (vesselId: string) => {
    setLoadingVesselDetails(true);
    try {
      let date: any = IUUDashboardService.getTimeFilterBounds();
      const data = await IUUClient.getVesselTrackDetails(
        userName,
        date[1],
        date[0],
        vesselId,
        'AIS'
      );

      setVesselTrackData(data);
    } catch (error) {
      setVesselTrackData(undefined);
      setAlertMessage(
        intl.formatMessage({
          id: I18nKey.ERROR_VESSEL_TRACK,
        }),
        'error'
      );
    } finally {
      setLoadingVesselDetails(false);
    }
  };

  const addVesselTracks = (response: VesselTrackDetails) => {
    const breachVesselData =
      IUUDashboardService.breachVesselSuccess(filteredBreachData);
    const vesselData = response[selectedVessel];
    const [
      vesselPathData,
      currentVesselPosition,
      vesselPointData,
      vesselPosition,
    ] = IUUDashboardService.renderVesselTrackData(
      selectedVessel,
      vesselData,
      Object.keys(breachVesselData).includes(selectedVessel)
        ? breachVesselData[selectedVessel]
        : {}
    );
    IUUDashboardService.addVesselTrackToMap(
      map,
      vesselPathData,
      vesselPosition,
      vesselPointData,
      currentVesselPosition
    );
    setLoadTracksPopup(true);
  };

  const onClickVessel = (selectedVessel: any) => {
    if (selectedVessel) {
      IUUDashboardService.removeAllLayers(map);
      setSelectedVessel(selectedVessel.vesselId);
    }
  };

  const setFilterData = (selectedFilters: any, apply: boolean) => {
    let selectedFenceName = _.map(selectedFilters.fenceNameList, 'name');
    let filterAISData: any = IUUDashboardService.getfilteredVesselData(
      AISData,
      selectedFilters.country,
      selectedFilters.vesselTypeList
    );
    setFilteredAISData(filterAISData);

    let filterBreaches: any = [];
    filterAISData.forEach((f: any) => {
      let d = _.find(
        breachData,
        (b) => Number(b.vessel.vesselId) === Number(f.vesselId)
      );
      if (d) {
        filterBreaches.push({ ...d, vessel: f });
      }
    });

    let filterBreachData: any = IUUDashboardService.getfilteredBreachData(
      filterBreaches,
      selectedFilters.country,
      selectedFilters.vesselTypeList,
      selectedFenceName
    );
    setFilteredBreachData(filterBreachData);

    let filterFenceData: any = IUUDashboardService.getfilteredFenceData(
      geofenceData,
      selectedFenceName
    );

    const [totalBreach, maxBreachCountry, maxBreachVessel] =
      IUUDashboardService.getTotalBreaches(filterBreachData);
    setTotalBreaches(totalBreach);
    setMaxBreachesCountry(maxBreachCountry);
    setMaxBreachVessel(maxBreachVessel);
    const chartData: any = IUUDashboardService.renderCharts(
      selectedVesselView === 'all' ? filterAISData : filterBreachData,
      selectedVesselView === 'breached'
    );

    const sunburstData: any = IUUDashboardService.renderSunburstChart(
      filterBreachData,
      filterFenceData
    );
    setRenderedChartData(chartData);
    setRenderedSunburstChartData(sunburstData);
    setReRenderMap(!reRenderMap);
  };

  const onCountryChange = (e: any) => {
    setSelectedCountry(e);
  };

  const onVesselTypeChange = (e: any) => {
    setSelectedVesselType(e);
  };

  const onFenceChange = (e: any) => {
    setSelectedFenceNameList(e);
  };

  const onVesselViewChange = (e: any) => {
    IUUDashboardService.removeTrackLayers(map);
    setSelectedVesselView(e.target.value);
  };

  const onApply = () => {
    setLoading(true);
    let selectedFilters: selectedFilters = {
      country: selectedCountry.length ? selectedCountry : [],
      vesselTypeList: selectedVesselType.length ? selectedVesselType : [],
      fenceNameList: selectedFenceNameList.length ? selectedFenceNameList : [],
    };
    setFilterData(selectedFilters, true);
    setLoading(false);
  };

  const resetFiltersToDefault = () => {
    setSelectedCountry(prevSelectedFilters?.country);
    setSelectedVesselType(prevSelectedFilters?.vesselTypeList);
    setSelectedFenceNameList(prevSelectedFilters?.fenceNameList);
    let selectedFilters: selectedFilters = {
      country: prevSelectedFilters?.country ? prevSelectedFilters?.country : [],
      vesselTypeList: prevSelectedFilters?.vesselTypeList
        ? prevSelectedFilters?.vesselTypeList
        : [],
      fenceNameList: prevSelectedFilters?.fenceNameList
        ? prevSelectedFilters?.fenceNameList
        : [],
    };
    setFilterData(selectedFilters, true);
  };

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      className={IUUDashboardClasses.mainContainerStyle}
    >
      {alert.display && (
        <Snackbar
          type={alert.type}
          display={alert.display}
          message={alert.message}
        ></Snackbar>
      )}
      {(loading || loadingBreachData) && (
        <ProgressSpinner showSpinner={loading} />
      )}
      <Summary
        totalBreaches={totalBreaches}
        maxBreachesCountry={maxBreachesCountry}
        maxBreachesVessel={maxBreachesVessel}
      />
      <Filters
        onCountryChange={onCountryChange}
        onVesselTypeChange={onVesselTypeChange}
        onFenceChange={onFenceChange}
        countryList={countryList}
        vesselTypeList={vesselTypesList}
        fenceNameList={fenceNameList}
        selectedVesselType={selectedVesselType}
        selectedCountry={selectedCountry}
        selectedFenceName={selectedFenceNameList}
        onFilterReset={resetFiltersToDefault}
        onApply={onApply}
      />
      <Widgets
        userName={userName}
        chartData={renderedChartData}
        sunburstChartData={renderedSunburstChartData}
        filteredBreachData={filteredBreachData}
        filteredAISData={filteredAISData}
        geofenceData={geofenceData}
        onSelectedVessel={onClickVessel}
        setLoading={setLoading}
        loadingVesselDetails={loadingVesselDetails}
        selectedVesselView={selectedVesselView}
        onVesselViewChange={onVesselViewChange}
        loadPopup={loadPopup}
        loadTracksPopup={loadTracksPopup}
        map={map}
      />
    </Container>
  );
};

export default AISMain;
